import React from "react";
import {Link} from "react-router-dom"
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
export const AboutHeroSection = () => {
  return (
    <>
      <div className="relative min-h-screen bg-[#010D1F] overflow-hidden">
        <div
          className="absolute inset-0 opacity-60 object-center"
          style={{
            backgroundImage:
              "url('https://html.designingmedia.com/artelligence/assets/images/banner-background.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div className="max-w-7xl mx-auto sm:px-0 px-2">
          <div className="grid md:grid-cols-2 gap-12 items-center  min-h-svh  pt-24 md:pt-0">
            <div className="z-10">
              <h1 className=" text-5xl sm:text-6xl font-semibold bg-gradient-to-r from-blue-500 via-red-400 to-green-500 bg-clip-text text-transparent lendings mb-6">
              About Us
              </h1>
              <p className="text-gray-300 mb-8 max-w-xl text-lg text-justify">
              Take charge of your investments with FinRain, a smart trading designed to help you make more profit with less effort. Our advanced AI-powered technology allows you to trade 24/7 without the hassle of manual trading. Whether you are a beginner or an expert, FinRain makes trading simpler, safer, and more efficient for everyone.
              </p>
              <div className="flex space-x-4">
                <div className="flex space-x-4">
                  <div className="group">
                    <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-2 flex justify-between gap-2 items-center text-white rounded-r-full rounded-tl-full px-4 transition-all duration-300 group-hover:bg-gradient-to-r group-hover:from-blue-800 group-hover:to-cyan-600">
                     <Link to='/'className="hover:text-green-100 hover:underline">Home</Link>
                     
                     <MdKeyboardDoubleArrowRight className="text-xl"/>
                      <Link to='/about'className="hover:text-green-100 hover:underline">About</Link>
                    </div>
                  </div>

                  
                </div>
              </div>
            </div>

            <div className="relative z-10">
              <img
                src="https://html.designingmedia.com/artelligence/assets/images/sub-bannerimage.png"
                alt="AI Robot"
                className="w-full h-auto object-cover absolute -right-20 -top-28"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
