import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../BaseFile/comman/Loader";
import { getUser } from "../redux/userSlice";


const thresholds = [2500, 5000, 10000, 20000, 50000, 100000, 200000, 500000];
const rewardValues = [50, 100, 200, 500, 1250, 3000, 8000,25000];
const salleryValues = [25, 50, 100, 200, 500, 1500, 3000, 10000];
const UserRewardDetail = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { loading, singleuser } = useSelector((state) => state.allusers);

  useEffect(() => {
    dispatch(getUser(auth?.id));
  }, [dispatch, auth?.id]);
  const reward_level = singleuser?.reward_level || 0;
console.log(reward_level)
  return (
    <>
      <div className="">
        <div className="relative flex flex-col w-full h-full mb-4 text-gray-200 bg-blue-900/50 px-3 py-1 shadow-md  bg-clip-border">
          {loading ? (
            <Loader />
          ) : (
            <div className="overflow-x-auto px-2">
        <h1 className="py-3">Reward Detail</h1>

              <table className="w-full text-left table-auto min-w-max border bg-blue-900/50">
                <thead>
                  <tr>
                    <th className="p-2 md:p-4 border-b border-slate-200 ">
                      <p className="text-xs md:text-lg font-normal leading-none text-white">
                        ID
                      </p>
                    </th>
                    <th className="p-2 md:p-4 border-b border-slate-200 ">
                      <p className="text-xs md:text-lg font-normal leading-none text-white">
                        Required Amount
                      </p>
                    </th>
                    <th className="p-2 md:p-4 border-b border-slate-200 ">
                      <p className="text-xs md:text-lg font-normal leading-none text-white">
                      Salary Amount
                      </p>
                    </th>
                    <th className="p-2 md:p-4 border-b border-slate-200 ">
                      <p className="text-xs md:text-lg font-normal leading-none text-white">
                        Reward Amount
                      </p>
                    </th>
                    <th className="p-2 md:p-4 border-b border-slate-200 ">
                      <p className="text-xs md:text-lg font-normal leading-none text-white">
                        Business Ratio
                      </p>
                    </th>
                   
                    <th className="p-2 md:p-4 border-b border-slate-200  text-center">
                      <p className="text-xs md:text-lg font-normal leading-none text-white text-center">
                        Achieve
                      </p>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-green-900/50">
                  {thresholds?.length > 0 ? (
                  thresholds.map((item, index) => {
                    return (
                      <tr key={index} className="even:bg-red-900/50  even:text:white text-gray-300">
                        <td className="whitespace-nowrap py-2 md:py-4 pl-4 pr-3 text-xs md:text-lg font-medium text-gray-300 sm:pl-3">
                          {index + 1}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 md:py-4 text-xs md:text-lg text-gray-300">
                          ${item}
                        </td>
                       
                        <td className="whitespace-nowrap px-3 py-2 md:py-4 text-xs md:text-lg text-gray-300">
                          ${salleryValues[index]}
                        </td>
                         <td className="whitespace-nowrap px-3 py-2 md:py-4 text-xs md:text-lg text-gray-300">
                          ${rewardValues[index]}
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 md:py-4 text-xs md:text-lg text-gray-300">
                          40% : 30% : 30%
                        </td>
                        
                        <td className="whitespace-nowrap px-3 py-2 md:py-4 text-xs md:text-lg text-gray-300 text-center">
                          {reward_level <= index ? (
                            <div className="bg-red-300 text-black p-2 rounded">
                              {" "}
                              Remain{" "}
                            </div>
                          ) : (
                            <div className="bg-green-300 text-black p-2 rounded">
                              {" "}
                              Archive{" "}
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                  })):(
                    <tr>
              <td
                colSpan={6} 
                className="whitespace-nowrap py-4 text-center text-lg font-medium text-gray-300 bg-amber-900"
              >
                No data available
              </td>
            </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserRewardDetail;
