import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import { CiDollar } from "react-icons/ci";
import {
  getctoList,
  checkCto,
  deleteCto,
  clearErrors,
  clearMessage,
} from "../redux/ctoSlice";
import PayctoModel from "./PayctoModel";
import { AiFillDelete } from "react-icons/ai";
import Loader from "../BaseFile/comman/Loader";
import { Confirmation } from "../BaseFile/comman/Confirmation";
const AdminCto = () => {
  const dispatch = useDispatch();
  const { allcto, loading, message, error } = useSelector((state) => state.cto);
  const [deleteID, setDeleteID] = useState();
  const [payId, setPayId] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [openModel, setOpenModel] = useState(null);


  useEffect(() => {
    dispatch(getctoList());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message]);

  const handleDelete = (id) => {
    setDeleteID(id);
    setModalOpen(true);
  };
  const handlePay = (id) => {
    setPayId(id);
    setOpenModel(true);
  };
  const isClose = () => {
    setModalOpen(false);
  };

  return (
    <>
         {message && <SuccessAlert message={message} />}
         {error && <ErrorAlert error={error} />}
      <div className="mt-4 bg-blue-900/50 p-4">
        <div className="w-full flex justify-between items-center mb-3 pl-3">
          <div>
            <h3 className="text-lg font-semibold text-slate-300">
              Pyemnt Settings Detail
            </h3>
            <p className="text-white text-lg">
              Overview of the Cto History.
            </p>
          
          </div>
          <div className="ml-3 flex justify-center gap-3">
          <button className="text-white bg-green-800/50 hover:bg-green-800 px-5 py-2 rounded-sm" onClick={()=>dispatch(checkCto())}>
              CheckCto
            </button>
            <div className="w-full flex gap-5 items-center max-w-sm relative">
              <div className="relative">
                <input
                  className="bg-blue-900/50 w-full pr-11 h-10 pl-3 py-2  placeholder:text-slate-400 text-slate-200 text-lg border border-slate-200 rounded transition duration-200 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md"
                  placeholder="Search for invoice..."
                />
                <button
                  className="absolute h-8 w-8 right-1 top-1 my-auto px-2 flex items-center bg-blue-800 rounded"
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="currentColor"
                    className="w-8 h-8 text-slate-300"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                  </svg>
                </button>
              </div>
             
            </div>
          </div>
        </div>

        <div className={`${loading ? "h-[260px] items-center" : "h-full"}`}>
          {loading ? (
            <Loader />
          ) : (
            <div className=" ">
              <div className=" flow-root">
                <div className=" overflow-x-auto">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-2">
                    <h1 className="text-white text-xl font-medium ">
                    Cto List History
                    </h1>
                    <table className="z-10 mt-6 w-full whitespace-nowrap text-left">
                      <thead className="border-b border-white/10 text-lg leading-6 text-white">
                        <tr>
                          <th
                            scope="col"
                            className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
                          >
                            Email
                          </th>
                          <th
                            scope="col"
                            className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
                          >
                            Paid
                          </th>
                          <th
                            scope="col"
                            className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                          >
                            Gift
                          </th>
                          <th
                            scope="col"
                            className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                          >
                            Category
                          </th>
                          <th
                            scope="col"
                            className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                          >
                            Amount
                          </th>
                          <th
                            scope="col"
                            className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
                          >
                            Monthly
                          </th>
                          <th
                            scope="col"
                            className="hidden py-2 pl-0 pr-4 font-semibold sm:table-cell sm:pr-3 "
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-white/5">
                      {allcto?.map((item,index)=>(
                          <tr key={index}>
                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                              <div className="">
                                <div className="font-mono text-lg leading-6 text-gray-400">
                                  {item?.username}
                                </div>
                              </div>
                            </td>
                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                              <div className="">
                                <div className="font-mono text-lg leading-6 text-gray-400">
                                  {item?.useremail}
                                </div>
                              </div>
                            </td>{" "}
                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                              <div className="">
                                <div className="font-mono text-lg leading-6 text-gray-400">
                                  {item?.paid}
                                </div>
                              </div>
                            </td>
                              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                                <div className="">
                                  <div className="font-mono text-lg leading-6 text-gray-400">
                                    {item?.gift}
                                  </div>
                                </div>
                              </td>
                              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                                <div className="">
                                  <div className="font-mono text-lg leading-6 text-gray-400">
                                    {item?.status}
                                  </div>
                                </div>
                              </td>
                              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                                <div className="">
                                  <div className="font-mono text-lg leading-6 text-gray-400">
                                    {item?.category}
                                  </div>
                                </div>
                              </td>
                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell">
                              <div className="">
                                <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                                  {item?.amount}
                                </div>
                              </div>
                            </td>
                            <td className="hidden py-4 pl-0 pr-4 sm:table-cell">
                              <div className="">
                                <div className="rounded-md bg-gray-700/40 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-white/10">
                                  {item?.monthly_amount}
                                </div>
                              </div>
                            </td>
                            <td className="py-4 pl-0 pr-4 text-right text-lg leading-6 text-gray-400 sm:table-cell sm:pr-3">
                              <div className="z-0 flex space-x-4">
                                <>
                                  <AiFillDelete
                                    className="h-4 w-4 text-red-400 cursor-pointer"
                                    onClick={() => handleDelete(item?.id)}
                                    title="Delete"
                                  />
                                  <CiDollar
                                    className="h-4 w-4 text-red-400 cursor-pointer"
                                    onClick={() => handlePay(item?.id)}
                                    title="Pay"
                                  />
                                </>
                              </div>
                            </td>
                          </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {modalOpen && (
          <Confirmation
            isClose={isClose}
            deletefunction={deleteCto}
            id={deleteID}
          />
        )}
        {openModel && (
          <PayctoModel
          openModel={openModel}
            isClose={()=>setOpenModel(false)}
            payid={payId}
          />
        )}
      
      </div>
    </>
  );
};

export default AdminCto;
