import { FaArrowRightLong } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";

export default function Features() {
  const steps = [
    {
      id: 1,
      title: "Sign Up & Get Started",
      description: " Create your account in minutes and access the trading dashboard.",
      icon: <FaArrowRightLong className="text-2xl text-blue-500" />,
      textColor: "text-blue-500",
      borderColor: "border border-blue-300 border-[3px]",
    },
    {
      id: 2,
      title: " Fund Your Account ",
      description: " Deposit funds securely and explore multiple trading options.",
      icon: <FaArrowRightLong className="text-2xl text-blue-500" />,
       textColor: "text-blue-500",
       borderColor: "border border-blue-300 border-[3px]",
    },
    {
      id: 3,
      title: "Trade with Confidence",
      description: " Use real-time insights, advanced tools, and market analysis to make smart trades.",
      icon: <TiTick className="text-2xl text-green-500" />,
       textColor: "text-green-500",
       borderColor: "border border-green-300 border-[3px]",
       
    },
  ];

  return (
    <div className="max-w-7xl mx-auto sm:px-0 px-2 py-12">
      <div className=" text-center max-w-3xl mx-auto">
      <span className="bg-gradient-to-r from-blue-500 to-green-500 text-transparent font-semibold bg-clip-text text-base">Simple 3 steps</span>
        <h1 className="text-3xl font-semibold text-[#0A1F44] mb-1">
        How FinRain Works
        </h1>
        
        <p className="text-[#3f4453] text-base">
        Simple, Secure, and Profitable—Trade Smarter with FinRain and Maximize Your Earnings!

        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-10">
        {steps.map((step) => (
          <div
            key={step.id}
            className="group bg-white shadow-sm border p-6 border-gray-300 relative overflow-hidden transition-all duration-500 hover:scale-105"
          >
            <div className="absolute bottom-0 left-0 w-0 h-[4px] bg-green-600 transition-all duration-300 group-hover:w-full"></div>
            <div className="relative mb-4 flex justify-between items-center">
              <div className={`w-[52px] h-[52px] rounded-full bg-blue-100 flex items-center ${step.borderColor} justify-center transition-transform duration-300 group-hover:scale-110`}>
                <span className={` ${step.textColor} font-semibold`}>{step.id}</span>
              </div>
              <div className="flex items-center justify-center">
                <span>{step.icon}</span>
              </div>
            </div>
            <h3 className="text-[#0A1F44] text-base font-semibold mb-1 text-left">
              {step.title}
            </h3>
            <p className="text-[#43464e] text-lg text-left">
              {step.description}
            </p>
          </div>
        ))}
      </div>
      {/* <div className="mt-12 flex justify-center">
        <button className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-lg text-lg font-medium">
          Open an Account
        </button>
      </div> */}
    </div>
  );
}
