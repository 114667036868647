import React from "react";
import { FaArrowRight } from "react-icons/fa";

const services = [
  {
    title: "Powerful Tools ",
    description: "Get the best analysis and insights for smart trading.",
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32 14H26V8M32 14V8H26M32 14L26 8"
          stroke="#06B6D4"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 8H8V14M14 8V14H8M14 8L8 14"
          stroke="#06B6D4"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 26V32H14M8 26H14V32M8 26L14 32"
          stroke="#06B6D4"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26 32H32V26M26 32V26H32M26 32L32 26"
          stroke="#06B6D4"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx="20" cy="20" r="4" stroke="#06B6D4" strokeWidth="2" />
      </svg>
    ),
  },
  {
    title: " Live Market Data",
    description: " Make informed decisions with real-time updates.",
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 32C26.6274 32 32 26.6274 32 20C32 13.3726 26.6274 8 20 8C13.3726 8 8 13.3726 8 20C8 26.6274 13.3726 32 20 32Z"
          stroke="#06B6D4"
          strokeWidth="2"
        />
        <path
          d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z"
          stroke="#06B6D4"
          strokeWidth="2"
        />
        <path d="M20 16V8" stroke="#06B6D4" strokeWidth="2" />
        <path d="M28 20H32" stroke="#06B6D4" strokeWidth="2" />
        <path d="M20 32V24" stroke="#06B6D4" strokeWidth="2" />
        <path d="M8 20H16" stroke="#06B6D4" strokeWidth="2" />
      </svg>
    ),
  },
  {
    title: " Secure Trading",
    description: " Advanced security ensures your funds are safe.",
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 28C16.134 28 13 24.866 13 21C13 17.134 16.134 14 20 14C23.866 14 27 17.134 27 21C27 24.866 23.866 28 20 28Z"
          stroke="#06B6D4"
          strokeWidth="2"
        />
        <path d="M20 14V8" stroke="#06B6D4" strokeWidth="2" />
        <path d="M10 21H30" stroke="#06B6D4" strokeWidth="2" />
      </svg>
    ),
  },
  {
    title: "User-Friendly Platform",
    description: " Start trading in just a few clicks.",
    icon: (
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20L16 14M16 14L22 20M16 14L16 26"
          stroke="#06B6D4"
          strokeWidth="2"
        />
        <path
          d="M22 20L28 14M28 14L34 20M28 14L28 26"
          stroke="#06B6D4"
          strokeWidth="2"
        />
        <path d="M16 26L22 20M22 20L28 26" stroke="#06B6D4" strokeWidth="2" />
      </svg>
    ),
  },
];

export default function WhyChoose() {
  return (
    <div className="bg-blue-300/50 py-16 sm:px-0 px-2">
      <div className="mx-auto max-w-7xl">
        <div className="mb-10 text-center">
          <h2 className="mb-2 text-lg font-medium uppercase tracking-wider text-green-600">
            SERVICES WE PROVIDE
          </h2>
          <h2 className="sm:text-4xl text-3xl font-semibold bg-gradient-to-r from-red-700 via-blue-600 to-green-500 bg-clip-text text-transparent mb-1">
          Why Choose FinRain?
          </h2>

          <p className="mb-8 max-w-2xl mx-auto text-base text-gray-700">
          Trade smarter with our feature-rich platform and unlock new opportunities.

          </p>
        </div>

        <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
          {services.map((service, index) => (
            <div
              key={index}
              className="relative overflow-hidden rounded-md bg-white hover:shadow-green-300 shadow-green-300 p-8 shadow-sm transition-all hover:shadow-lg flex flex-col"
            >
              <div className="absolute -right-20 -top-20 h-40 w-40 rounded-full bg-green-300/50"></div>
              <div className="relative flex-grow">
                <div className="mb-6 inline-block rounded-full bg-blue-100 p-4">
                  {service.icon}
                </div>
                <h3 className="mb-1 text-lg font-semibold text-gray-900">
                  {service.title}
                </h3>
                <p className="mb-4 text-base text-gray-600">
                  {service.description}
                </p>
              </div>
              <button className="text-lg font-medium text-blue-500 hover:text-green-500 mt-auto text-left">
                READ MORE
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
