import { FaArrowRight } from 'react-icons/fa'; // Importing the ArrowRight icon from react-icons
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const caseStudies = [
  {
    category: "Robot Technology",
    title: "Officia deserunt mollitia animi nobis",
    image: "https://html.designingmedia.com/artelligence/assets/images/case-image1.png",
  },
  {
    category: "User Research",
    title: "Eum iure reprehenderit velit esse",
    image: "https://html.designingmedia.com/artelligence/assets/images/case-image2.png",
  },
  {
    category: "Machine Learning",
    title: "Duis aute irure dolor in reprehenderit",
    image: "https://html.designingmedia.com/artelligence/assets/images/case-image3.png",
  },
  {
    category: "Data Science",
    title: "Numquam eius modi tempora incidunt labore",
    image: "https://html.designingmedia.com/artelligence/assets/images/case-image4.png",
  },
  {
    category: "Artificial Intelligence",
    title: "Placeat non recusae deleniti",
    image: "https://html.designingmedia.com/artelligence/assets/images/case-image5.png",
  },
  {
    category: "UI Execution",
    title: "Exercitation ullamco laboris nisi",
    image: "https://html.designingmedia.com/artelligence/assets/images/case-image6.png",
  },
];

const CaseStudyCard = ({ category, title, image }) => (
  <div className="group relative overflow-hidden rounded-md">
    <img
      src={image || "/placeholder.svg"}
      alt={title}
      width={600}
      height={400}
      className="h-[400px] w-full object-cover transition-transform duration-300 group-hover:scale-110"
    />
    <div className="absolute inset-0 bg-gradient-to-b from-black/5 via-black/25 to-black/50">
      <div className="flex h-full flex-col justify-end p-6 text-white">
        <span className="mb-2 text-lg font-medium">{category}</span>
        <h3 className="mb-4 text-xl font-semibold">{title}</h3>
        <button className="flex h-12 w-12 items-center justify-center rounded-full bg-blue-500 transition-transform duration-300 group-hover:translate-x-2">
          <FaArrowRight className="h-6 w-6 text-white" />
        </button>
      </div>
    </div>
  </div>
);

const BusinessStats = () => {
  return (
    <section className="bg-white  pt-16 sm:px-0 px-2">
      <div className="mx-auto max-w-7xl">
        <div className="mb-10 text-center">
          <h2 className="mb-1 text-lg font-medium uppercase tracking-wider text-green-600">Blog</h2>
          <h1 className="sm:text-4xl text-3xl font-semibold bg-gradient-to-r from-red-700 via-blue-600 to-green-500 bg-clip-text text-transparent mb-1">Explore Our Blogs</h1>
        </div>

        <div className="mb-12">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            grabCursor={true}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
            breakpoints={{
              550: { slidesPerView: 1 },
              768: { slidesPerView: 2 },
              1024: { slidesPerView: 4 },
            }}
            modules={[Autoplay, Navigation]}
            className="swiper w-full"
          >
            {caseStudies.map((study, index) => (
              <SwiperSlide key={index}>
                <CaseStudyCard {...study} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>

        {/* <div className="text-center">
          <button className="group inline-flex items-center gap-2 rounded-tl-full rounded-r-full bg-gradient-to-r from-blue-500 to-blue-900 px-6 py-3 text-lg font-semibold text-white transition-all hover:gap-3">
            View All
            <FaArrowRight className="h-4 w-4 transition-transform group-hover:translate-x-1" />
          </button>
        </div> */}
      </div>
    </section>
  );
};

export default BusinessStats;
