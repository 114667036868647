import React from "react";
import { Link } from "react-router-dom";
export function HeroSection() {
  return (
    <div className="relative min-h-screen bg-[#010D1F] overflow-hidden">
      <div
        className="absolute inset-0 opacity-60 object-center"
        style={{
          backgroundImage:
            "url('https://html.designingmedia.com/artelligence/assets/images/banner-background.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <div className="max-w-7xl mx-auto sm:px-0 px-2">
        <div className="grid md:grid-cols-2 gap-12 items-center min-h-screen pt-24 md:pt-0">
          <div className="z-10">
            <h1 className=" text-5xl sm:text-6xl font-semibold bg-gradient-to-r from-blue-500 via-red-400 to-green-500 bg-clip-text text-transparent lendings mb-6">
            FinRain Smart Trading, Maximum Profits
            </h1>
            <p className="text-gray-300 mb-8 max-w-xl text-justify">
            We provide a secure, easy-to-use platform where beginners and experienced traders can access real-time market data, powerful tools, and a seamless trading experience. Maximize your potential with a reliable trading space designed for success.
            </p>
            <div className="flex space-x-4">
              <div className="sm:flex sm:space-x-4">
                <Link to="/registration">
                  <div className="group">
                    <button className="bg-gradient-to-r from-blue-600 to-blue-800 py-2 flex justify-between items-center text-white rounded-r-full rounded-tl-full pl-4 pr-2 transition-all duration-300 group-hover:bg-gradient-to-r group-hover:from-blue-800 group-hover:to-cyan-600">
                      Start Trading Now
                      <p className="ml-2 bg-blue-900 h-8 w-8 flex justify-center items-center rounded-full transition-all duration-300 group-hover:bg-blue-800">
                        →
                      </p>
                    </button>
                  </div>
                </Link>
                <Link to="/contact">
                  <button className="text-white border border-white rounded-r-full rounded-tl-full pl-4 pr-2 py-2 flex justify-between items-center transition-all duration-300 hover:bg-blue-900/10 sm:mt-0 mt-5">
                    Contact Us
                    <p className="ml-2 bg-blue-900/50 h-8 w-8 flex justify-center items-center rounded-full transition-all duration-300 group-hover:bg-blue-800/50">
                      →
                    </p>
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="relative z-10 flex items-center justify-center">
            <img
              src="https://cdn-apinb.nitrocdn.com/LGFQTZTBRQFYZkDHnBAkeTYvUEPBCNKO/assets/images/optimized/rev-220ff1a/www.instancy.com/wp-content/uploads/2023/08/Home-bg-image.png"
              alt="AI Robot"
              className="w-full h-auto"
            />
             {/* <div className="flex items-center justify-center h-96 w-96 rounded-full shadow-lg border-2 border-red-700 shadow-blue-600 animate-spin-slow">
                <img
                  alt="Finrain Logo"
                  src="/finrain.png"
                  className="h-80 w-auto"
                />
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
