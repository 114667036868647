import { FiMessageSquare, FiShield } from "react-icons/fi";
import { Link } from "react-router-dom";
export default function AboutSection() {
  return (
    <div className="max-w-7xl mx-auto sm:px-0 px-2 py-12">
      <div className="grid md:grid-cols-2 gap-12 items-center">
        <div className="relative">
          <div className="relative w-[80%]">
            <img
              src="https://img.freepik.com/free-photo/ai-technology-microchip-background-futuristic-innovation-technology-remix_53876-124727.jpg?uid=R176823449&ga=GA1.1.1433286368.1718702777&semt=ais_hybrid"
              alt="Laptop with AI interface"
              className="w-full h-auto rounded-lg"
            />
          </div>
          <div className="absolute bottom-[-20%] right-0 w-[60%]">
            <div className="bg-white p-2 rounded-2xl shadow-lg">
              <img
                src="https://img.freepik.com/free-photo/robot-hand-finger-pointing-ai-technology-background_53876-129768.jpg?uid=R176823449&ga=GA1.1.1433286368.1718702777&semt=ais_hybrid"
                alt="Robotic hand"
                className="w-full h-auto rounded-xl"
              />
            </div>
          </div>
        </div>
        <div className="space-y-8">
          <div>
            <h3 className="text-blue-500 uppercase tracking-wide text-lg font-medium mb-4">
              ABOUT US
            </h3>
            <h2 className=" text-4xl  font-semibold bg-gradient-to-r from-blue-700 via-red-600 to-green-500 bg-clip-text text-transparent  mb-4">
            FinRain Simplified Trading for Everyone

            </h2>
            <p className="text-gray-600 leading-relaxed text-justify">
            FinRain is a user-friendly trading platform that makes buying and selling assets easy and secure. Whether you are new to trading or a seasoned investor, we offer real-time insights, advanced tools, and a smooth trading experience to help you make informed decisions.
            </p>
          </div>
          <div className="space-y-6">
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <div className="bg-blue-100 p-3 rounded-lg">
                  <FiMessageSquare className="w-6 h-6 text-blue-500" />
                </div>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-1">
                Simple & Fast 
                </h3>
                <p className="text-gray-600">
                Easy-to-use interface for hassle-free trading.
                </p>
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-shrink-0">
                <div className="bg-blue-100 p-3 rounded-lg">
                  <FiShield className="w-6 h-6 text-blue-500" />
                </div>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-1">
                Safe & Secure
                </h3>
                <p className="text-gray-500">
                Your funds and data are protected with top security measures.
                </p>
              </div>
            </div>
          </div>
           <Link to="/registration" >
          <button className="bg-gradient-to-r from-blue-600 to-blue-800 py-2 mt-6 flex justify-between items-center text-white rounded-r-full rounded-tl-full pl-4 pr-2 transition-all duration-300 group-hover:bg-gradient-to-r group-hover:from-blue-800 group-hover:to-cyan-600">
          Learn More
            <p className="ml-2 bg-blue-900 h-8 w-8 flex justify-center items-center rounded-full transition-all duration-300 group-hover:bg-blue-800">
              →
            </p>
          </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
