import { Link } from "react-router-dom" 
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"
import { IoIosCall } from "react-icons/io"
import { MdEmail } from "react-icons/md"
import { GiPositionMarker } from "react-icons/gi"

const usefulLinks = [
  { name: "Home", href: "/" },
  { name: "About", href: "/about" },
  { name: "Services", href: "/" },
  { name: "Contact us", href: "/contact" },
]

const ourServices = [
  { name: "Privacy", href: "/privacy" },
  { name: "Term & Condition ", href: "/" },
  { name: "Our Team", href: "/terms" },
  { name: "Faq", href: "/" },
]

const contactDetails = [
  // { icon: <IoIosCall className="h-5 w-5 text-blue-400 hover:text-blue-500" />, text: "+61 3 8376 6284" },
  {
    icon: <MdEmail className="h-5 w-5 text-blue-400  hover:text-blue-500" />,
    text: <Link href="mailto:info@artelligence.com" className="hover:text-white">info@gmail.com</Link>
  },
  {
    icon: <GiPositionMarker className="h-5 lg:w-9 sm:w-5 w-9 text-blue-400  hover:text-blue-500" />,
    text: (
      <span>
       Sai 2 Soi 16, Muang Pattaya, Bang Lamung District, Chonburi Province
        <br />
         20150
      </span>
    )
  }
]

export default function Footer() {
  return (
    <footer className="bg-[#001B3B] text-white">
      <div className="mx-auto max-w-7xl px-4 py-12 sm:px-0 px-2">
        <div className="grid gap-8 lg:grid-cols-4">
          {/* Company Info */}
          <div className="space-y-6">
          <div className="flex items-center justify-center h-20 w-20 rounded-full shadow-lg shadow-blue-600 animate-spin-slow">
                <img
                  alt="Finrain Logo"
                  src="/finrain.png"
                  className="h-16 w-auto"
                />
              </div>
            <p className="text-lg text-gray-300 text-justify">
            Powered by FinRain Smart, Secure, and Automated Trading for Everyone.
            </p>
            <div className="flex space-x-4">
              <Link href="#" className="rounded-full bg-white/10 p-2 hover:bg-white/20">
                <FaFacebookF className="h-5 w-5" />
              </Link>
              <Link href="#" className="rounded-full bg-white/10 p-2 hover:bg-white/20">
                <FaTwitter className="h-5 w-5" />
              </Link>
              <Link href="#" className="rounded-full bg-white/10 p-2 hover:bg-white/20">
                <FaLinkedinIn className="h-5 w-5 " />
              </Link>
            </div>
          </div>

          {/* Useful Links */}
          <div>
            <h3 className="mb-6 text-lg font-semibold">Company</h3>
            <ul className="space-y-3 text-lg text-gray-300">
              {usefulLinks.map((link, index) => (
                <li key={index}>
                  <Link to={link.href} className="hover:text-white">
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Our Services */}
          <div>
            <h3 className="mb-6 text-lg font-semibold">Our Services</h3>
            <ul className="space-y-3 text-lg text-gray-300">
              {ourServices.map((service, index) => (
                <li key={index}>
                  <Link to={service.href} className="hover:text-white">
                    {service.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Us */}
          <div>
            <h3 className="mb-6 text-lg font-semibold">Contact us</h3>
            <ul className="space-y-4 text-lg text-gray-300">
              {contactDetails.map((contact, index) => (
                <li key={index} className="flex  gap-3">
                  {contact.icon}
                  {contact.text}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="border-t border-white/10">
        <div className="mx-auto max-w-7xl px-4 py-4 text-center text-lg text-gray-400">
        Powered by FinRain Smart, Secure, and Automated Trading for Everyone.
        </div>
      </div>
    </footer>
  )
}
