import { useState } from "react"
import { AiOutlinePlus } from "react-icons/ai";

const faqs = [
  {
    question: "What services does your platform offer?",
    answer:
      "We offer automated trading solutions, AI-driven analytics, and a user-friendly interface to help maximize profits while minimizing risks.",
  },
  {
    question: "Is your platform suitable for beginners?",
    answer:
      "Absolutely! Our platform is designed to be intuitive for new traders while also providing advanced tools for experienced users.",
  },
  {
    question: "Are there any fees for using your platform?",
    answer:
      "Yes, transaction fees may apply depending on the type of trade and exchange platform used. Detailed fee structures are provided in your account dashboard.",
  },
  {
    question: "How secure is my account information?",
    answer:
      "We prioritize security by implementing advanced encryption and multi-factor authentication to safeguard your account.",
  },
  {
    question: "How can I contact support if I have an issue?",
    answer:
      "You can reach our support team via email, phone, or live chat available on our website. We're here to help 24/7.",
  },
]

export default function Faq() {
  const [openIndex, setOpenIndex] = useState(null)

  const handleSubmit = (e) => {
    e.preventDefault()
    // Handle form submission
  }

  return (
    <div className="mx-auto max-w-7xl  py-16 sm:px-0 px-2">
      <div className="grid gap-12 lg:grid-cols-2">
          <div className="mb-8">
            <h2 className="text-lg font-medium uppercase tracking-wide text-blue-500">FAQ,S</h2>
            <h1 className="mt-2 text-4xl font-semibold bg-gradient-to-r from-blue-700 via-red-500 to-green-600 bg-clip-text text-transparent">Frequently Asked Questions</h1>
            <p className="text-gray-800 mt-3 max-w-xl text-justify text-lg">
            Have questions about our services or platform? We've compiled a list of commonly asked questions to help you get the answers you need. Whether you're new to our platform or an experienced user, this section will guide you through essential details. If you can't find what you're looking for, feel free to contact us for further assistance.

            </p>
          </div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="overflow-hidden rounded-lg bg-white shadow-blue-900/50 border-gray-300 border shadow-sm transition-all duration-200 hover:shadow-md"
              >
                <button
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                  className="flex w-full items-center justify-between p-4 text-left"
                >
                  <span className="font-medium">{faq.question}</span>
                  <div
                    className={`rounded-full bg-blue-500 p-1 text-white transition-transform duration-200 ${
                      openIndex === index ? "rotate-45" : ""
                    }`}
                  >
                    <AiOutlinePlus className="h-4 w-4" />
                  </div>
                </button>
                {openIndex === index && <div className="border-t px-4 py-3 text-gray-600">{faq.answer}</div>}
              </div>
            ))}
          </div>
      
        {/* <div className="rounded-md bg-white border border-gray-200 shadow-blue-900/50 p-8 shadow-md">
          <h1 className=" text-2xl font-semibold bg-gradient-to-r from-blue-700 via-red-500 to-green-600 bg-clip-text text-transparent">Need any Help!</h1>
          <p className="mb-6 text-gray-600">
            Eiusmod tempor in labore et dolore magna aliqua ruis ultrices gravida sit amet.
          </p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              placeholder="Your Name"
              className="w-full rounded-lg border border-gray-200 text-lg p-2.5 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
            <input
              type="email"
              placeholder="Your Email"
              className="w-full rounded-lg border border-gray-200 p-2.5 text-lg focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
            <input
              type="tel"
              placeholder="Phone"
              className="w-full rounded-lg border border-gray-200 p-2.5 text-lg focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
            <textarea
              placeholder="Message"
              rows={4}
              className="w-full rounded-lg border border-gray-200 p-2.5 text-lg focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="group flex items-center gap-2 rounded-full bg-gradient-to-r from-blue-500 to-blue-400 px-6 py-3 font-semibold text-white transition-all hover:gap-3"
            >
              Submit Now
              <span className="transition-transform group-hover:translate-x-1">→</span>
            </button>
          </form>
        </div> */}
      </div>
    </div>
  )
}
