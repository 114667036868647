import { useEffect, useRef } from "react";

const UserTradingView = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.innerHTML = ""; 

      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = JSON.stringify({
        interval: "1m",
        width: "100%",
        height: 400,
        symbol: "BINANCE:BTCUSDT",
        showIntervalTabs: true,
        displayMode: "single",
        locale: "en",
        colorTheme: "dark", 
        isTransparent: false,
      });

      containerRef.current.appendChild(script);
    }
  }, []);

  return (
    <div className="w-full flex flex-col rounded-lg ">
      <div ref={containerRef} className="tradingview-widget-container bg-gray-800/80 w-full rounded-lg"></div>
    </div>
  );
};

export default UserTradingView;
