import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../BaseFile/comman/Loader";
import { Confirmation } from "../BaseFile/comman/Confirmation";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
import { AiFillDelete } from "react-icons/ai";
import {
  getAllAchivers,
  deleteAchivers,
  clearErrors,
  clearMessage,
} from "../redux/achiversSlice";
import AddAchiversModel from "./AddAchiversModel";

const AdminAchiver = () => {
  const dispatch = useDispatch();
  const { allachivers, loading, error, message } = useSelector((state) => state.achivers);
  const [deleteID, setDeleteID] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [openModel, setOpenModel] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    dispatch(getAllAchivers());
    if (error) {
      const errorInterval = setInterval(() => {
        dispatch(clearErrors());
      }, 3000);
      return () => clearInterval(errorInterval);
    }
    if (message) {
      const messageInterval = setInterval(() => {
        dispatch(clearMessage());
      }, 3000);
      return () => clearInterval(messageInterval);
    }
  }, [dispatch, error, message]);

  const handleDelete = (id) => {
    setDeleteID(id);
    setModalOpen(true);
  };

  const isClose = () => {
    setModalOpen(false);
  };

  function modelClose() {
    setOpenModel(false);
  }
  const handleImageClick = (imageName) => {
    setPreviewImage(`/uploads/achivers/${imageName}`);
  };

  const handleClosePreview = () => {
    setPreviewImage(null);
  };

  return (
    <>
{message && <SuccessAlert message={message} />}
{error && <ErrorAlert error={error} />}
<div className="sm:mx-2 mt-4 bg-blue-900/50 p-4">
  <div className="w-full lg:flex sm:flex flex-col sm:flex-row justify-between items-center mb-3 lg:pl-3 sm:pl-3">
    <div className="mb-2 sm:mb-0">
      <h3 className="text-lg font-semibold text-white">Achivers</h3>
      <p className="text-white text-lg">Overview of the Achivers.</p>
    </div>
    <div className="lg:ml-3 sm:ml-3  w-full sm:w-auto">
      <div className="flex gap-2 sm:gap-5 max-w-full sm:max-w-sm relative">
        <div className="relative flex-grow">
          <input
            className="bg-blue-900/50 w-full pr-11 h-10 pl-3 py-2  placeholder:text-slate-400 text-slate-200 text-lg border border-slate-200 rounded transition duration-200 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md"
            placeholder="Search for invoice..."
          />
          <button
            className="absolute h-8 w-8 right-1 top-1 my-auto px-2 flex items-center bg-blue-800 rounded"
            type="button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="3"
              stroke="currentColor"
              className="w-6 h-6 text-slate-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
              />
            </svg>
          </button>
        </div>
        <button
          type="button"
          onClick={() => setOpenModel(true)}
          className="block rounded-md bg-blue-800 px-5 py-2 text-center text-lg font-semibold text-white shadow-sm hover:bg-rose-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Add
        </button>
      </div>
    </div>
  </div>

  <div className="relative flex flex-col w-full h-full mb-4 text-gray-300  py-1 shadow-md rounded-lg bg-clip-border">
    {loading ? (
      <Loader />
    ) : (
      <div className="overflow-x-auto">
        <table className="w-full text-left table-auto min-w-max border">
          <thead>
            <tr>
              <th className="p-2 md:p-4 border-b border-slate-200 ">
                <p className="text-xs md:text-lg font-normal leading-none text-white">Name</p>
              </th>
              <th className="p-2 md:p-4 border-b border-slate-200 ">
                <p className="text-xs md:text-lg font-normal leading-none text-white">Description</p>
              </th>
              <th className="p-2 md:p-4 border-b border-slate-200 ">
                <p className="text-xs md:text-lg font-normal leading-none text-white">Recipt</p>
              </th>
              <th className="p-2 md:p-4 border-b border-slate-200 ">
                <p className="text-xs md:text-lg font-normal leading-none text-white">Request</p>
              </th>
              <th className="p-2 md:p-4 border-b border-slate-200 ">
                <p className="text-xs md:text-lg font-normal leading-none text-white">Action</p>
              </th>
            </tr>
          </thead>
          <tbody className="bg-red-800/50">
            {allachivers?.slice().reverse().map((item, index) => (
              <tr key={index} className="even:bg-green-900/50">
                <td className="whitespace-nowrap px-3 py-2 md:py-4 text-xs md:text-lg text-gray-300">
                  ${item?.username}
                </td>
                <td className="whitespace-nowrap px-3 py-2 md:py-4 text-xs md:text-lg text-gray-300">
                  {item?.description}
                </td>
                <td className="whitespace-nowrap px-3 py-2 md:py-4 text-xs md:text-lg text-gray-300">
                  <div className="flex items-center space-x-2">
                    {item?.image && (
                      <button
                        onClick={() => handleImageClick(item?.image)}
                        className="text-blue-400 hover:underline"
                      >
                        {item?.image?.slice(0, 10)}
                      </button>
                    )}
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-2 md:py-4 text-xs md:text-lg text-gray-300">
                  {item?.created_at}
                </td>
                <td className="whitespace-nowrap px-3 cursor-pointer py-2 md:py-4 text-xs md:text-lg text-gray-300">
                  <AiFillDelete onClick={()=>handleDelete(item?.id)}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
</div>

{previewImage && (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
    <div className="relative">
      <img
        src={previewImage}
        alt="Preview"
        className="max-w-[600px] max-h-[500px] object-contain"
      />
      <button
        onClick={handleClosePreview}
        className="absolute top-2 right-2 text-white text-xl bg-black p-2 rounded-full"
      >
        ×
      </button>
    </div>
  </div>
)}

{modalOpen && (
  <Confirmation isClose={isClose} deletefunction={deleteAchivers} id={deleteID} />
)}

{openModel && (
  <AddAchiversModel openModel={openModel} modelClose={modelClose} />
)}


    </>
  );
};

export default AdminAchiver;
