import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RadioGroup } from "@headlessui/react";

const pricingPlans = [
  {
    id: "basic",
    name: "Basic",
    duration: "",
    price: "$20",
    period: "1 Month",
    color: "orange",
  },
  {
    id: "advanced",
    name: "Advanced",
    duration: "10% ROI",

    price: "$50",
    period: "2 Months",
    discount: "20% Off regular price",
    color: "green",
  },
  {
    id: "premium",
    name: "Premium",
    duration: "10% ROI",

    price: "$200",
    period: "2 Months",
    discount: "20% Off regular price",
    color: "blue",
  },
  {
    id: "elite",
    name: "Elite",
    duration: "10% ROI",

    price: "$500",
    period: "3 Months",
    discount: "30% Off regular price",
    color: "red",
  },
];

export default function Pricing() {
  const [selectedPlan, setSelectedPlan] = useState("basic");
  const navigate = useNavigate();

  const handleSubscribe = () => {
    navigate(`/registration?${selectedPlan}`);
  };

  return (
    <div className="max-w-7xl mx-auto p-4 font-sans">
      <div className="text-center">
        <h2 className="sm:text-4xl text-3xl font-semibold bg-gradient-to-r from-red-700 via-blue-600 to-green-500 bg-clip-text text-transparent mb-1">
          Pricing Plans
        </h2>
        <p className="text-gray-500 text-lg">Change your plan according to your needs</p>
      </div>

      <div className="my-8">
        <RadioGroup value={selectedPlan} onChange={setSelectedPlan} className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5">
          {pricingPlans.map((plan) => (
            <RadioGroup.Option key={plan.id} value={plan.id} className="cursor-pointer">
              {({ checked }) => (
                <div className={`transition-all p-4 rounded-lg border-t-4 shadow-md border ${
                  checked ? `border-${plan.color}-500` : "border-gray-300"
                }`}>
                  <div className="relative">
                    <div className="absolute right-2 top-2">
                      <div className={`w-6 h-6 border-2 rounded-full flex items-center justify-center
                        ${checked ? `border-${plan.color}-500` : "border-gray-300"}`}>
                        {checked && <div className={`w-3 h-3 rounded-full bg-${plan.color}-500`} />}
                      </div>
                    </div>
                    <h3 className={`text-xl font-semibold text-${plan.color}-500`}>{plan.name}</h3>
                    <p className="text-gray-500 text-xs mt-1">{plan.duration}</p>
                  </div>
                  <div className="mt-6">
                    <h3 className="text-gray-800 text-4xl font-semibold">{plan.price}</h3>
                  </div>
                </div>
              )}
            </RadioGroup.Option>
          ))}
        </RadioGroup>
      </div>

      <div className="text-center mt-4 mb-6">
        <button
          onClick={handleSubscribe}
          className="bg-blue-600 hover:bg-blue-700 text-white font-medium py-2 px-6 rounded-sm transition-all"
        >
           Get Started 
        </button>
      </div>
    </div>
  );
}
