import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About Us", href: "/about" },
  { name: "Contact", href: "/contact" },
  { name: "Privacy", href: "/privacy" },
  { name: "Terms", href: "/terms" },
 
];

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation(); // Get current route path

  return (
    <div>
      {/* Navbar Section */}
      <header className="absolute inset-x-0 top-0 z-50">
        <nav
          aria-label="Global"
          className="flex items-center justify-between py-4 px-8"
        >
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Finrain</span>
              <div className="flex items-center justify-center h-20 w-20 rounded-full shadow-lg shadow-blue-600 animate-spin-slow">
                <img
                  alt="Finrain Logo"
                  src="/finrain.png"
                  className="h-16 w-auto"
                />
              </div>
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(true)}
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`text-base font-medium hover:text-green-400 relative 
                  ${
                    location.pathname === item.href
                      ? "text-green-400 border-b-2 border-green-400 pb-1"
                      : "text-gray-300"
                  }`}
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end items-center gap-4">
          <Link
              to="/registration"
              className="text-lg font-semibold text-white hover:text-gray-100 bg-blue-600 hover:bg-blue-700 py-1.5 px-4 rounded-md"
            >
              Register Now
            </Link>
            <Link
              to="/user/login"
              className="text-lg font-semibold text-white hover:text-green-400 hover:underline"
            >
              Log in <span aria-hidden="true">&rarr;</span>
            </Link>
            
          </div>
        </nav>

        {/* Mobile Menu */}
        <Dialog
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
          className="lg:hidden"
        >
          <div className="fixed inset-0 z-50" />
          <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full max-w-sm bg-gray-900 px-6 py-6 sm:ring-1 sm:ring-white/10">
            <div className="flex items-center justify-between">
              <Link to="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Finrain</span>
                <div className="flex items-center justify-center h-20 w-20 rounded-full shadow-lg shadow-blue-600 animate-spin-slow">
                <img
                  alt="Finrain Logo"
                  src="/finrain.png"
                  className="h-16 w-auto"
                />
              </div>
              </Link>
              <button
                type="button"
                onClick={() => setMobileMenuOpen(false)}
                className="-m-2.5 rounded-md p-2.5 text-gray-400"
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`block px-3 py-2 text-base font-semibold hover:bg-gray-800 rounded-lg 
                    ${
                      location.pathname === item.href
                        ? "text-green-400 border-b-2 border-green-400 pb-1"
                        : "text-white"
                    }`}
                  onClick={() => setMobileMenuOpen(false)} // Close menu on click
                >
                  {item.name}
                </Link>
              ))}
              <Link
              to="/user/register"
              className="text-lg mt-4 font-semibold text-white hover:text-gray-100 bg-blue-600 hover:bg-blue-700 py-1.5 px-4 rounded-md"
            >
              Register Now
            </Link>
              <Link
                to="/user/login"
                className="mt-4 block px-3 py-2 text-base font-semibold text-white hover:bg-gray-800 rounded-lg"
                onClick={() => setMobileMenuOpen(false)}
              >
                Log in
              </Link>
            </div>
          </DialogPanel>
        </Dialog>
      </header>
    </div>
  );
}
