import React, { useEffect, useRef, memo } from "react";

const TreadingView = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.innerHTML = ""; // Clear existing content before appending script

      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = JSON.stringify({
        autosize: true,
        symbol: "BINANCE:BTCUSDT",
        interval: "D",
        timezone: "Etc/UTC",
        theme: "dark",
        style: "1",
        locale: "en",
        allow_symbol_change: true,
        calendar: false,
        support_host: "https://www.tradingview.com",
      });

      containerRef.current.appendChild(script);
    }
  }, []);

  return (
    <div className=" py-8  shadow-sm bg-blue-50">
    <div className="max-w-7xl sm:px-0 px-2 mx-auto h-[600px]">
      {/* Title Section */}
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-3xl sm:text-4xl font-semibold bg-gradient-to-r from-blue-500 via-red-400 to-green-500 bg-clip-text text-transparent mb-8">
          FinRain Smart Trading View Chart
        </h1>
      </div>
  
      {/* Chart Container */}
      <div className="w-full bg-gray-800/80 rounded-lg h-[500px] overflow-hidden">
        <div
          ref={containerRef}
          className="tradingview-widget-container w-full h-full"
        >
          <div className="tradingview-widget-container__widget w-full h-full"></div>
        </div>
      </div>
    </div>
  </div>
  
  );
};

export default TreadingView;
