import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createNotification,
  resetState,
  addBanner,
} from "../redux/notificationSlice";
import Spinner from "../BaseFile/comman/Spinner";
import { getAllUsers } from "../redux/userSlice";
import SuccessAlert from "../BaseFile/comman/SuccessAlert";
import ErrorAlert from "../BaseFile/comman/ErrorAlert";
// Validation schema
const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  message: Yup.string().required("Message is required"),
  users: Yup.boolean(),
  type: Yup.string()
    .oneOf(["notification", "site_popup"], "Invalid type")
    .required("Type is required"),
});

const NotificationForm = () => {
  const dispatch = useDispatch();
  const { allusers } = useSelector((state) => state.allusers);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]); // Track selected users
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [file, setFile] = useState(null);

  const { loading, error, message } = useSelector(
    (state) => state.notifications
  );

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
    if (error) {
      setOpenError(true);
    }
  }, [message, error]);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleUserSearch = (searchTerm) => {
    if (searchTerm) {
      const filteredUsers = allusers?.filter((user) =>
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setSearchResults(filteredUsers);
    } else {
      setSearchResults([]);
    }
  };

  const handleUserSelect = (user) => {
    if (selectedUsers.includes(user.id)) {
      setSelectedUsers(selectedUsers.filter((id) => id !== user.id)); // Remove user if already selected
    } else {
      setSelectedUsers([...selectedUsers, user.id]); // Add user to selection
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) {
      console.log("Select a file");
      return;
    }
    setFile(selectedFile);
    const formData = new FormData();
    formData.append("image", selectedFile);
    console.log("File appended to formData");

    dispatch(addBanner(formData))
      .then((response) => {
        console.log("Banner added successfully", response);
      })
      .catch((error) => {
        console.error("Error adding banner:", error);
      });
  };
  return (
    <>
      {message && <SuccessAlert message={message} />}
      {error && <ErrorAlert error={error} />}
      <div className="w-7xl mx-auto bg-blue-900/50 px-4 my-4 pb-4">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 ">
        <div className="bg-blue-800/50 rounded-lg shadow-md mt-4 p-5 border border-gray-400 shadow-blue-500">
        <h1 className="text-2xl font-medium text-gray-200 mb-4">
          Create Notification
        </h1>
        <Formik
          initialValues={{
            title: "",
            message: "",
            users: true,
            type: "notification",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            // Prepare values including selected user IDs
            const notificationData = { ...values, recipients: selectedUsers };
            console.log(notificationData);
            dispatch(createNotification(notificationData));
            // resetForm();
          }}
        >
          {({ errors, touched, setFieldValue, values }) => (
            <Form className="">
              <div className="grid grid-cols-1">
                <div className="mb-4">
                  <label className="block text-gray-300 text-lg font-semibold mb-1">
                    Title
                  </label>
                  <Field
                    name="title"
                    as="input"
                    placeholder="Enter Title"
                    className="block w-full p-2 bg-blue-900/50 text-white text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-600 text-lg mt-1"
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="block text-gray-300 text-lg font-semibold mb-1">
                  Message
                </label>
                <Field
                  name="message"
                  as="textarea"
                  className="block w-full p-3 border bg-blue-900/50 text-white border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="4"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="text-red-600 text-lg mt-1"
                />
              </div>

              <div className="flex items-center mb-4">
                <Field
                  type="checkbox"
                  name="users"
                  checked={values.users}
                  className="mr-2"
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setFieldValue("users", isChecked);
                    if (isChecked) {
                      setSelectedUsers([]); // Clear selected users when checked
                    }
                  }}
                />
                <label className="text-gray-300 text-lg font-semibold">
                  All Users
                </label>
              </div>

              {!errors.users && !values.users && (
                <div className="mb-4">
                  <label className="block text-gray-700 text-lg font-semibold mb-1">
                    Search User
                  </label>
                  <input
                    type="text"
                    placeholder="Search user"
                    onChange={(e) => handleUserSearch(e.target.value)}
                    className="block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {searchResults.length > 0 && (
                    <div className="mt-2 bg-gray-100 rounded-md p-2 border border-gray-300">
                      {searchResults.map((user) => (
                        <div key={user.id} className="flex items-center mb-2">
                          <Field
                            type="checkbox"
                            name="userId"
                            checked={selectedUsers.includes(user.id)}
                            onChange={() => handleUserSelect(user)}
                            className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                          <label className="text-gray-700">
                            {user.username}{" "}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {/* Show selected users */}
              {selectedUsers.length > 0 && (
                <div className="mb-4">
                  <label className="block text-gray-700 text-lg font-semibold mb-1">
                    Selected Users:
                  </label>
                  <div className="p-2 border border-gray-300 rounded-md bg-gray-50">
                    {selectedUsers.map((userId) => {
                      const user = allusers.find((u) => u.id === userId);
                      return user ? (
                        <span
                          key={userId}
                          className="bg-blue-100 text-blue-800 py-1 px-2 rounded mr-2"
                        >
                          {user.username}
                        </span>
                      ) : null;
                    })}
                  </div>
                </div>
              )}

              <button
                type="submit"
                disabled={loading}
                className={`w-full py-2 rounded-md text-white font-semibold ${
                  loading
                    ? "bg-blue-300 opacity-70 cursor-not-allowed"
                    : "bg-blue-500 hover:bg-blue-600 transition duration-200"
                }`}
              >
                {loading ? <Spinner /> : "Create"}
              </button>
            </Form>
          )}
        </Formik>
        </div>
        <div className="bg-blue-900/50 rounded-lg shadow-md  mt-4  p-5 border shadow-blue-500">
        <h1 className="text-2xl font-semibold text-gray-200 mb-4">
       Upload Banner
        </h1>
        <div className="mb-4">
          <input
            id="file"
            type="file"
            name="image"
            onChange={handleFileChange}
            className="  cursor-pointer w-full text-white p-[5px] border border-gray-300 rounded-md text-lg"
          />
        </div>
        </div>
        
        </div>
      </div>
    </>
  );
};

export default NotificationForm;
