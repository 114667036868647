import React from "react";
import { Link } from "react-router-dom";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
export const ContactHeroSection = () => {
  return (
    <>
      <div className="relative min-h-screen bg-[#010D1F] overflow-hidden">
        <div
          className="absolute inset-0 opacity-60 object-center"
          style={{
            backgroundImage:
              "url('https://html.designingmedia.com/artelligence/assets/images/banner-background.png')",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div className="max-w-7xl mx-auto px-2 sm:px-0">
          <div className="grid md:grid-cols-2 gap-12 items-center min-h-screen pt-24 md:pt-0">
            <div className="z-10">
              <h1 className=" text-5xl sm:text-6xl font-semibold bg-gradient-to-r from-blue-500 via-red-400 to-green-500 bg-clip-text text-transparent lendings mb-6">
              Contact Us
              </h1>
              <p className="text-gray-300 mb-8 max-w-xl">
              We’re here to help! Whether you have questions about our trading, need assistance, or want to provide feedback, feel free to reach out.
              </p>
              <div className="flex space-x-4">
                <div className="flex space-x-4">
                  <div className="group">
                    <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-2.5 flex justify-between gap-2 items-center text-white rounded-r-full rounded-tl-full px-4 transition-all duration-300 group-hover:bg-gradient-to-r group-hover:from-blue-800 group-hover:to-cyan-600">
                      <Link to="/" className="hover:text-green-100 hover:underline">Home</Link>
                      <MdKeyboardDoubleArrowRight className="text-xl" />
                      <Link to="/contact"className="hover:text-green-100 hover:underline">Contact</Link>
                    </div>
                  </div>

                 
                </div>
              </div>
            </div>

            <div className="relative z-10">
              <img
                src="https://cdn-apinb.nitrocdn.com/LGFQTZTBRQFYZkDHnBAkeTYvUEPBCNKO/assets/images/optimized/rev-220ff1a/www.instancy.com/wp-content/uploads/2023/08/Home-bg-image.png"
                alt="AI Robot"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
