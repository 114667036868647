import React, { useState, useEffect } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../comman/ErrorAlert";
import SuccessAlret from "../comman/SuccessAlert";
import { loginUser, clearErrors } from "../../redux/authSlice";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../comman/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Header from "../../CoreFile/Header";
import Footer from "../../CoreFile/Footer";
import {sendForgotLink,clearMessage,clearErrors as clrerr} from "../../redux/forgotSlice"
export default function Login() {
  const [showPass, setShowPass] = useState(false);
  const { loading, error, auth } = useSelector((state) => state.auth);
  const { loading:load, message ,error:Err} = useSelector((state) => state.forgot);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email or username is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      dispatch(loginUser(values));
    },
  });

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (Err) {
      const timer = setTimeout(() => {
        dispatch(clrerr());
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (message) {
      const timer = setTimeout(() => {
        dispatch(clearMessage());
      }, 2000);
      return () => clearTimeout(timer);
    }
    if (auth) {
      navigate(`/${auth?.role}/dashboard`);
    }
  }, [error, dispatch, auth,message,Err]);

  const handleForgotPass=()=>{
    if(formik.values.email==""){
      alert("enter email")
    }
    const forgotData={email:formik.values.email ,role:'user'}
    console.log(forgotData)
    dispatch(sendForgotLink(forgotData))
  }
  console.log(Err)
  return (
    <>
    <Header/>
    <div className="relative min-h-screen flex justify-center items-center pt-20 pb-6">
      {/* Background Image */}
      <div
        className="absolute inset-0 h-full w-full bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: `url(/bot126.jpg)` }}  // Replace with your image path
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Form Container */}
      <div className="relative z-10 bg-black bg-opacity-75 rounded-lg p-4 shadow-lg max-w-md w-full">
        <div className="mx-auto w-full max-w-sm">
          {/* Logo */}
          <div className="text-center flex items-center justify-between">
          <div>
            <h2 className="mt-6 text-left text-4xl text-green-600 font-bold leading-9 tracking-tight">
              Login
            </h2>
            <p className="mt-2 text-center text-white">Have an account?</p>
            </div>
            <Link to="/">
            <div className="flex items-center justify-center h-20 w-20 rounded-full shadow-lg shadow-blue-600 animate-spin-slow">
                <img
                  alt="Finrain Logo"
                  src="/finrain.png"
                  className="h-16 w-auto"
                />
              </div>
            </Link>
            
           
          </div>

          {/* Form */}
          <div className="mt-8">
            <form className="space-y-4" onSubmit={formik.handleSubmit}>
              <div className="w-full">
                <label htmlFor="email" className="block text-lg font-medium text-white">
                  Email or Username
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    required
                    className="block w-full rounded-full bg-white bg-opacity-10 text-white py-2 px-4 focus:outline-none sm:text-lg"
                  />
                </div>
                {formik.touched.email && formik.errors.email && (
                  <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                    {formik.errors.email}*
                  </p>
                )}
              </div>

              <div className="w-full relative ">
                <label htmlFor="password" className="block text-lg font-medium text-white">
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type={showPass ? "text" : "password"}
                    required
                    className="block w-full rounded-full bg-white bg-opacity-10 text-white py-2 px-4 focus:outline-none sm:text-lg"
                  />
                  <span
                    onClick={() => setShowPass(!showPass)}
                    className="absolute inset-y-0 right-0 top-[47px] transform -translate-y-1/2 pr-3 flex items-center text-black cursor-pointer"
                  >
                    {showPass ? <FaRegEyeSlash className="text-white"/> : <FaRegEye className="text-white"/>}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <p className="text-red-500 tracking-widest text-xs mt-2 text-left">
                    {formik.errors.password}*
                  </p>
                )}
              </div>

              {error && <ErrorAlert error={error} />}
              {Err && <ErrorAlert error={Err} />}
              {message && <SuccessAlret message={message} />}
              <div className="flex justify-between items-center">
                <label className="flex items-center text-white">
                  <input type="checkbox" className="form-checkbox rounded text-white" />
                  <span className="ml-2">Remember Me</span>
                </label>
                <span onClick={()=>handleForgotPass()} className="text-white text-lg cursor-pointer">
                  Forgot Password
                </span>
              </div>

              <div>
                <button
                  type="submit"
                  className={`w-full uppercase tracking-widest justify-center rounded-full ${
                    loading ? "bg-green-500" : "bg-green-500"
                  } px-6 py-2 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-green-600 focus:outline-none`}
                >
                  {(loading  || load)? <Spinner /> : "Sign In"}
                </button>
              </div>
            </form>

            <p className="mt-4 text-center text-white text-lg">
              Not a member?{" "}
              <Link to="/registration" className="font-semibold leading-6 text-blue-500 hover:text-blue-600 hover:underline">
                Register Here
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
}
