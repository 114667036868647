import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Spinner from "../comman/Spinner";
import { signupUser, clearErrors, clearMessage } from "../../redux/authSlice";
import ErrorAlert from "../comman/ErrorAlert";
import SuccessAlert from "../comman/SuccessAlert";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Header from "../../CoreFile/Header";
import Footer from "../../CoreFile/Footer";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Registration() {
  
  const [referralCode, setReferralCode] = useState(null);
  const query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, message,auth } = useSelector((state) => state.auth);

  useEffect(() => {
    const referral = query.get("referral");
    if (referral) {
      setReferralCode(referral);
    }
    if (auth) {
      navigate(`/${auth?.role}/dashboard`);
    }
  }, [query,auth]);

  const [showPass, setShowPass] = useState(false);



  const initialValues = {
    fullname: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    referralBy: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Incorrect email").required("Email is required"),
    fullname: Yup.string().required("fullname is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
    phone: Yup.number().required("Phone is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (referralCode) {
        values.referralBy = referralCode;
      }
      dispatch(signupUser(values));
    },
  });


  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        dispatch(clearErrors());
      }, 10000);
      return () => clearTimeout(timer);
    }
    if (message) {
      const timer = setTimeout(() => {
        dispatch(clearMessage());
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [error, dispatch,message]);

  return (
    <>
      <Header />
      <div
        className="relative min-h-screen flex items-center justify-center bg-cover bg-center pt-28 pb-8"
        style={{ backgroundImage: `url('/bot1261.jpg')` }}
      >
        {/* Overlay for darkening the background */}
        <div className="absolute inset-0 bg-black opacity-50"></div>

        {/* Form container aligned to the right with right padding */}
        <div className="relative bg-white bg-opacity-10  backdrop-blur-lg shadow-lg rounded-lg p-8 max-w-lg w-full sm:mx-0 mx-3">
          <div className="text-center flex items-center justify-between mb-10">
            <div>
              <h2 className=" text-left text-3xl text-green-500 font-bold leading-9 tracking-tight">
                Create your account
              </h2>
              <p className="mt-1 text-left text-base text-white">
                Start your journey with us today
              </p>
            </div>
            <Link to="/" className="sm:block hidden">
              <div className="flex items-center justify-center h-20 w-20 rounded-full shadow-lg shadow-blue-600 animate-spin-slow">
                <img
                  alt="Finrain Logo"
                  src="/finrain.png"
                  className="h-16 w-auto"
                />
              </div>
            </Link>
          </div>

          <form className="space-y-6" onSubmit={formik.handleSubmit}>
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
              <div className="w-full">
                <label
                  htmlFor="email"
                  className="block text-lg  font-medium text-white"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  placeholder="Enter Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="mt-2 block w-full text-lg bg-gray-700 text-gray-200 bg-opacity-10  backdrop-blur-lg rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.email && formik.errors.email && (
                  <p className="text-red-500 text-xs mt-2">
                    {formik.errors.email}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label
                  htmlFor="fullname"
                  className="block text-lg  font-medium text-white"
                >
                  Full Name
                </label>
                <input
                  id="fullname"
                  name="fullname"
                  type="text"
                  required
                  placeholder="Enter fullname"
                  value={formik.values.fullname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="mt-2 block w-full text-lg bg-gray-700 text-gray-200 bg-opacity-10  backdrop-blur-lg rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.fullname && formik.errors.fullname && (
                  <p className="text-red-500 text-xs mt-2">
                    {formik.errors.fullname}
                  </p>
                )}
              </div>
              <div className="w-full">
                <label
                  htmlFor="username"
                  className="block text-lg font-medium text-white"
                >
                  Phone
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="number"
                  placeholder="Enter Phone"
                  required
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="mt-2 block w-full text-lg bg-gray-700 bg-opacity-10  backdrop-blur-lg text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
                />
                {formik.touched.phone && formik.errors.phone && (
                  <p className="text-red-500 text-xs mt-2">
                    {formik.errors.phone}
                  </p>
                )}
              </div>
            </div>

            <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
              {/* Password */}
              <div className="w-full">
                <label
                  htmlFor="password"
                  className="block text-lg font-medium text-white "
                >
                  Password
                </label>
                <div className="relative mt-2">
                  <input
                    id="password"
                    name="password"
                    placeholder="Enter password"
                    type={showPass ? "text" : "password"}
                    required
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="block w-full text-lg bg-gray-700 text-gray-200 rounded-md py-2 px-4 placeholder-gray-400 border border-gray-500 bg-opacity-10  backdrop-blur-lg focus:ring focus:ring-blue-500 focus:border-blue-500"
                  />
                  <span
                    onClick={() => setShowPass(!showPass)}
                    className="absolute right-4 top-3 cursor-pointer text-gray-300 "
                  >
                    {showPass ? <FaRegEyeSlash /> : <FaRegEye />}
                  </span>
                </div>
                {formik.touched.password && formik.errors.password && (
                  <p className="text-red-500 text-xs mt-2">
                    {formik.errors.password}
                  </p>
                )}
              </div>

              {/* Confirm Password */}
              <div className="w-full">
                <label
                  htmlFor="confirmPassword"
                  className="block text-lg font-medium text-white "
                >
                  Confirm Password
                </label>
                <div className="relative mt-2">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type={showPass ? "text" : "password"}
                    required
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="block w-full text-lg bg-gray-700 text-gray-200 rounded-md py-2 px-4 bg-opacity-10  backdrop-blur-lg placeholder-gray-400 border border-gray-500 focus:ring focus:ring-blue-500 focus:border-blue-500"
                  />
                </div>
                {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && (
                    <p className="text-red-500 text-xs mt-2">
                      {formik.errors.confirmPassword}
                    </p>
                  )}
              </div>
            </div>

            {/* Referral Code */}
            <div>
              <label
                htmlFor="referralBy"
                className="block text-lg font-medium text-white "
              >
                Referral Code
              </label>
              <input
                id="referralBy"
                name="referralBy"
                placeholder="Enter Referral Code"
                type="text"
                required
                value={referralCode || formik.values.referralBy}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="mt-2 block w-full text-lg bg-gray-700 text-gray-200 rounded-md py-2 px-4 bg-opacity-10  backdrop-blur-lg placeholder-gray-400 border border-gray-500 focus:ring focus:ring-amber-500 focus:border-amber-500"
                disabled={!!referralCode}
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-green-500 text-white text-lg rounded-md py-2 px-4 hover:bg-green-600  focus:ring focus:ring-green-500 focus:ring-opacity-50"
              disabled={loading}
            >
              {loading ? <Spinner /> : "Sign Up"}
            </button>
          </form>

          <p className="mt-6 text-left text-gray-300 text-center">
            Already a member?{" "}
            <Link
              to="/user/login"
              className="text-white font-medium hover:text-green-600 hover:underline"
            >
              Login Here
            </Link>
          </p>
        </div>
      </div>
      <Footer />
      {/* Error and Success Alerts */}
      {error && <ErrorAlert error={error} />}
      {message && <SuccessAlert message={message} />}
    </>
  );
}
