import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
export default function TradingSection() {
  return (
    <div className="relative  overflow-hidden ">
      {/* Background grid pattern */}
      <div
        className="absolute inset-0 "
        style={{
          backgroundImage:
            "url('https://html.designingmedia.com/artelligence/assets/images/choose-background.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />

      {/* Background geometric lines */}
      <div className="absolute inset-0">
        <div className="absolute right-0 -bottom-40 h-80 w-80 ">
         <img src="https://html.designingmedia.com/artelligence/assets/images/choose-sideshape2.png"/>
        </div>
      </div>

      <div className="relative mx-auto max-w-7xl  py-0 sm:px-0 px-2">
      <div className="grid grid-cols-1 gap-12 lg:grid-cols-2 lg:gap-8">
        {/* Left Column - Image */}
        <div className="lg:order-first order-last flex items-center justify-center lg:justify-start relative">
          <img
            src="/treadings.png"
            alt="AI Robot with tablet"
            className="h-auto w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-xl object-contain"
          />
        </div>

        {/* Right Column - Content */}
        <div className="lg:order-last order-first flex flex-col justify-center text-white">
          <span className="mb-4 text-lg font-medium uppercase tracking-wider text-green-400">
            Our Vision
          </span>
          <h1 className="text-3xl sm:text-4xl font-semibold bg-gradient-to-r from-blue-500 via-red-400 to-green-500 bg-clip-text text-transparent mb-6">
          Making Trading Easy & Accessible for All

          </h1>
          <p className="mb-8 text-gray-300">
          We believe trading should be simple, secure, and available to everyone. FinRain is built to empower traders with easy-to-use features, insightful data, and a safe environment to grow their investments.
          </p>

          {/* Bullet Points */}
          <div className="mb-8 space-y-4">
            {[
              "Always Evolving : We keep improving our platform with the latest innovations.",
              "For Everyone : Designed for beginners and experts alike.",
              "Trade with Confidence Minimize risks with reliable market insights.",
            ].map((point, index) => (
              <div key={index} className="flex items-center gap-2">
                <FiArrowRight className="h-5 w-5 text-blue-500" />
                <span className="text-gray-300">{point}</span>
              </div>
            ))}
          </div>

            <Link to="/registration" >
          <button className="group flex w-fit items-center gap-2 bg-gradient-to-r rounded-r-full rounded-tl-full from-blue-600 to-blue-800 px-6 py-3 text-lg font-semibold text-white transition-all hover:gap-3 hover:shadow-lg">
            Discover More
            <FiArrowRight className="h-4 w-4 transition-all group-hover:translate-x-1" />
          </button>
          </Link>
        </div>
      </div>
    </div>
    </div>
  );
}
