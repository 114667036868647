import React from "react";
import {
  FaBuilding,
  FaUsers,
  FaChartBar,
  FaUsersCog,
  FaClipboardList,
  FaNewspaper,
} from "react-icons/fa";
import Header from "../../CoreFile/Header";
import Footer from "../../CoreFile/Footer";
import { AboutHeroSection } from "./AboutHeroSection";

export const About = () => {
  const statsData = [
    {
      icon: <FaBuilding className="text-blue-500 w-6 h-6" />,
      title: "Founded",
      description:
        "We started with a mission to make trading smarter, automated, and accessible to everyone",
    },
    {
      icon: <FaChartBar className="text-green-500 w-6 h-6" />,
      title: "50M+ Trades Executed",
      description:
        "Our smart algorithms have successfully handled over 50 million trades, helping traders reach their financial goals with accuracy.",
    },
    {
      icon: <FaUsers className="text-purple-500 w-6 h-6" />,
      title: "400K+ Active Users",
      description:
        "Trusted by a global community, FinRain consistently delivers reliable solutions to improve your trading experience.",
    },
  ];

  const infoData = [
    {
      icon: <FaUsersCog className="text-red-500 w-6 h-6" />,
      title: "Our Team",
      description:
        "A dedicated group of experienced traders, AI developers, and financial experts work together to build an easy-to-use and efficient trading platform.",
    },
    {
      icon: <FaClipboardList className="text-yellow-500 w-6 h-6" />,
      title: "Our Board",
      description:
        "Our leadership team consists of industry professionals who guide FinRain toward continuous innovation and cutting-edge solutions.",
    },
    {
      icon: <FaNewspaper className="text-indigo-500 w-6 h-6" />,
      title: "Press & Media",
      description:
        "Featured on top finance and tech platforms, FinRain is recognized as a game-changer in the world of automated trading.",
    },
  ];
  return (
    <>
      <Header />
      <AboutHeroSection />
      <div className="max-w-7xl mx-auto px-4 sm:px-2 sm:px-0 py-12">
        {/* Hero Section */}
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-12 items-center mb-20">
          <div>
            <span className="bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text text-lg border-blue-500  border-b-[2px] ">
              About Us
            </span>
            <h1 className="text-4xl font-semibold leading-snug mb-3 mt-2">
            We Make Automated Trading Easy and Profitable
            </h1>
            <p className="text-gray-600 text-base text-justify">
            Take charge of your investments with FinRain, a smart trading designed to help you make more profit with less effort. Our advanced AI-powered technology allows you to trade 24/7 without the hassle of manual trading. Whether you are a beginner or an expert, FinRain makes trading simpler, safer, and more efficient for everyone.
            </p>
          </div>
          <div className="relative sm:h-[400px] rounded-lg overflow-hidden">
            <img
              src="https://img.freepik.com/free-photo/robot-with-final-piece-jigsaw-puzzle_1048-3550.jpg?uid=R176823449&ga=GA1.1.1433286368.1718702777&semt=ais_hybrid"
              alt="Office workspace"
              fill
              className="object-cover"
            />
          </div>
        </div>

        {/* Stats Section */}
        <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1  gap-8 mb-20">
          {statsData.map((stat, index) => (
            <div
              key={index}
              className="p-6 rounded-lg border border-gray-300 shadow-md text-center"
            >
              <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mb-4 mx-auto">
                {stat.icon}
              </div>
              <h3 className="text-base font-semibold mb-1">{stat.title}</h3>
              <p className="text-gray-600 text-lg">{stat.description}</p>
            </div>
          ))}
        </div>

        {/* Mission Section */}
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mb-20">
          <div className="">
            <h2 className="text-2xl font-semibold mb-3">Our Mission</h2>
            <p className="text-gray-600 text-justify text-lg">
             At FinRain, we aim to make trading easier and more efficient using automation and AI. We remove the complexities of manual trading and provide tools that help traders make better decisions, reduce risks, and maximize profits.

            </p>
            <p className="text-gray-600 text-justify text-lg mt-2">
              With cutting-edge technology and AI-driven strategies, we ensure
              that every trader, regardless of experience, has access to
              profitable trading solutions. Our goal is to eliminate the
              complexities of trading, allowing users to trade confidently and
              efficiently. We are committed to continuous innovation, ensuring
              that our platform evolves to meet the ever-changing market needs.
            </p>
          </div>
          <div className="grid gap-4">
            {infoData.map((info, index) => (
              <div
                key={index}
                className="flex items-start gap-4 p-4 rounded-lg border border-gray-200"
              >
                <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center flex-shrink-0">
                  {info.icon}
                </div>
                <div>
                  <h3 className="text-base font-semibold mb-1">{info.title}</h3>
                  <p className="text-gray-600 text-lg">{info.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
