export default function Banner() {
  return (
    <div className="relative  bg-slate-900 overflow-hidden">
      {/* Content Container */}
      <div className="relative z-10 max-w-7xl mx-auto py-16 sm:px-0 px-2 flex flex-col justify-center h-[450px]">
        <div className="max-w-xl">
          <h1 className="text-2xl font-semibold text-left leadings text-white mb-4 sm:text-4xl ">
          Trade Smarter, Earn More
          </h1>
          <p className="text-gray-300 mb-8 text-base text-justify">
          Join FinRain and take control of your trading journey. Whether you're just starting or looking to improve your strategies, our platform offers everything you need to trade with confidence.
          </p>
          <div className="flex justify-start">
          <button className="inline-flex items-center px-6 py-3 border-2 border-white text-base font-medium text-white hover:bg-white hover:text-slate-900 transition-colors duration-200 sm:text-lg">
          Start Trading Now
          </button>
          </div>
        </div>
      </div>
      <div className="absolute right-0 top-0 h-full w-full sm:w-1/2">
        <div className="absolute inset-0 flex items-center justify-end">
          <img
            src="/bott.png"
            alt="Festive reindeer decoration"
            className="h-auto  w-80 object-contain transform "
          />
        </div>
      </div>

      {/* Optional: Add snow-like dots effect */}
      <div className="absolute inset-0 z-0">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: "radial-gradient(circle, rgba(255,255,255,0.1) 1px, transparent 1px)",
            backgroundSize: "20px 20px",
          }}
        />
      </div>
    </div>
  );
}
