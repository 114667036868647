import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateWithdrawal } from "../redux/withdrawalSlice";
import { ethers } from "ethers";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useWeb3ModalState,
  useDisconnect,
} from "@web3modal/ethers/react";

// Configure Web3Modal
createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata: {
      name: "USDT Transfer App",
      description: "Transfer USDT across different networks",
    },
  }),
  chains: [
    {
      chainId: 56,
      name: "BNB Smart Chain",
      currency: "BNB",
      explorerUrl: "https://bscscan.com",
      rpcUrl: "https://bsc-dataseed.binance.org",
    },
  ],
  projectId: "b00311bb20f1d71b977b474eac2b7dcd", // Get this from cloud.walletconnect.com
});

const USDT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955"; // BSC USDT
const USDT_ABI = [
  "function balanceOf(address) view returns (uint256)",
  "function decimals() view returns (uint8)",
  "function transfer(address, uint256) returns (bool)",
];

const USDTTransfer = ({ user, id }) => {
  const dispatch = useDispatch();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { address, isConnected } = useWeb3ModalAccount();
  const { chainId } = useWeb3ModalState();
  const { walletProvider } = useWeb3ModalProvider();

  const [bnbBalance, setBnbBalance] = useState("0.00");
  const [usdtBalance, setUsdtBalance] = useState("0.00");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchBalances = async () => {
    try {
      const provider = new ethers.BrowserProvider(walletProvider);

      const bnbBalance = await provider.getBalance(address);
      setBnbBalance(parseFloat(ethers.formatEther(bnbBalance)).toFixed(4));

      const usdtContract = new ethers.Contract(
        USDT_ADDRESS,
        [
          "function balanceOf(address) view returns (uint256)",
          "function decimals() view returns (uint8)",
        ],
        provider
      );

      const decimals = await usdtContract.decimals();
      const usdtBalance = await usdtContract.balanceOf(address);
      setUsdtBalance(
        parseFloat(ethers.formatUnits(usdtBalance, decimals)).toFixed(2)
      );
    } catch (err) {
      console.error("Error fetching balances:", err);
      setError("Failed to fetch balances");
    }
  };

  const handleTransfer = async () => {
    try {
      setIsLoading(true);
      setError("");

      if (!user.amount || isNaN(user.amount))
        throw new Error("Invalid transfer amount");
      if (!user.bep20) throw new Error("Invalid recipient address");

      await switchToBNBChain();

      const provider = new ethers.BrowserProvider(walletProvider);
      const signer = await provider.getSigner();

      const usdtContract = new ethers.Contract(
        USDT_ADDRESS,
        [
          "function transfer(address, uint256) returns (bool)",
          "function decimals() view returns (uint8)",
          "function balanceOf(address) view returns (uint256)",
        ],
        signer
      );

      const decimals = await usdtContract.decimals();
      const currentBalance = await usdtContract.balanceOf(address);
      const transferAmount = ethers.parseUnits(
        user.amount.toString(),
        decimals
      );

      if (currentBalance < transferAmount) {
        throw new Error("Insufficient USDT balance");
      }

      const tx = await usdtContract.transfer(user.bep20, transferAmount);
      await tx.wait();

      dispatch(
        updateWithdrawal({
          id,
          status: "complete",
          amount: user?.amount + user.deduction,
          user_id: user?.id,
        })
      );

      await fetchBalances();
      setIsModalOpen(false);
    } catch (err) {
      console.error("Transfer error:", err);
      setError(err.message || "Transfer failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const disconnectWallet = () => {
    disconnect(); // Disconnects the session
  };
  const switchToBNBChain = async () => {
    try {
      await walletProvider?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }], // BSC Mainnet
      });
    } catch (err) {
      if (err.code === 4902) {
        try {
          await walletProvider?.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x38",
                chainName: "BNB Smart Chain",
                nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
                rpcUrls: ["https://bsc-dataseed.binance.org/"],
                blockExplorerUrls: ["https://bscscan.com/"],
              },
            ],
          });
        } catch (addError) {
          setError("Failed to add BSC network");
        }
      } else {
        setError("Failed to switch network");
      }
    }
  };

  const handleModal = async () => {
    setIsModalOpen(true);
    await open();
  };

  return (
    <div>
      {isConnected ? (
        <button
          className="w-full bg-yellow-500 text-white px-5 py-2 rounded-xl hover:bg-yellow-600 shadow-xl font-semibold"
          onClick={() => setIsModalOpen(true)}
        >
          Send USDT
        </button>
      ) : (
        <button
          className="w-full bg-yellow-500 text-white px-5 py-2 rounded-xl hover:bg-yellow-600 shadow-xl font-semibold"
          onClick={handleModal}
        >
          Connect Wallet
        </button>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg w-full max-w-xl p-6">
            <div className="bg-yellow-50 p-4 rounded-lg">
              <p className="text-sm text-gray-600">BNB Balance</p>
              <p className="text-2xl font-bold text-yellow-600">
                {bnbBalance} BNB
              </p>
            </div>

            <div className="bg-green-50 p-4 rounded-lg">
              <p className="text-sm text-gray-600">USDT Balance</p>
              <p className="text-2xl font-bold text-green-600">
                ${usdtBalance}
              </p>
            </div>

            <select
              className="bg-white text-black px-3 py-2 rounded-2xl font-semibold border"
              onChange={(e) =>
                e.target.value === "disconnect" && disconnectWallet()
              }
            >
              <option value="account">
                {address?.slice(0, 6)}...{address?.slice(-4)}
              </option>
              <option value="disconnect">Disconnect</option>
            </select>

            <div className="bg-gray-50 p-4 rounded-lg space-y-3">
              <p className="text-sm text-gray-500">Recipient Address</p>
              <p className="text-sm break-all font-medium text-black">
                {user?.bep20}
              </p>

              <p className="text-sm text-gray-500">Amount</p>
              <p className="text-lg font-semibold text-black">
                ${Number(user?.amount).toFixed(2)} USDT
              </p>

              <button
                onClick={handleTransfer}
                disabled={isLoading}
                className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-4 rounded transition-colors duration-200 disabled:opacity-50"
              >
                {isLoading ? (
                  <AiOutlineLoading3Quarters className="animate-spin" />
                ) : (
                  "Confirm USDT Transfer"
                )}
              </button>

              {error && (
                <div className="mt-4 p-3 bg-red-100 border text-red-700 rounded">
                  {error}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default USDTTransfer;
// import React, { useState, useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { updateWithdrawal } from "../redux/withdrawalSlice";
// import { ethers } from "ethers";
// import { AiOutlineLoading3Quarters } from "react-icons/ai";
// import { useAccount, useConnect, useDisconnect } from "wagmi";
// const USDT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955";
// const BNB_CHAIN_ID = "0x38";

// const USDTTransfer = ({ user, id }) => {
//   const dispatch = useDispatch();
//   const { address, isConnected } = useAccount();
//   const { connect, connectors } = useConnect();
//   const connector = connectors[0];
//   const { disconnect } = useDisconnect();
//   const [bnbBalance, setBnbBalance] = useState("0.00");
//   const [usdtBalance, setUsdtBalance] = useState("0.00");

//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [error, setError] = useState("");

//   useEffect(() => {
//     if (isConnected && address) {
//       fetchBalances();
//       const interval = setInterval(fetchBalances, 15000);
//       return () => clearInterval(interval);
//     }
//   }, [isConnected, address]);

//   const fetchBalances = async () => {
//     try {
//       const provider = new ethers.BrowserProvider(window.ethereum);

//       const bnbBalance = await provider.getBalance(address);
//       setBnbBalance(parseFloat(ethers.formatEther(bnbBalance)).toFixed(4));

//       const usdtContract = new ethers.Contract(
//         USDT_ADDRESS,
//         ["function balanceOf(address) view returns (uint256)", "function decimals() view returns (uint8)"],
//         provider
//       );

//       const decimals = await usdtContract.decimals();
//       const usdtBalance = await usdtContract.balanceOf(address);
//       setUsdtBalance(parseFloat(ethers.formatUnits(usdtBalance, decimals)).toFixed(2));
//     } catch (err) {
//       console.error("Error fetching balances:", err);
//       setError("Failed to fetch balances");
//     }
//   };

//   const handleTransfer = async () => {
//     try {
//       setIsLoading(true);
//       setError("");

//       if (!user.amount || isNaN(user.amount)) throw new Error("Invalid transfer amount");
//       if (!user.bep20) throw new Error("Invalid recipient address");

//       await switchToBNBChain();

//       const provider = new ethers.BrowserProvider(window.ethereum);
//       const signer = await provider.getSigner();

//       const usdtContract = new ethers.Contract(
//         USDT_ADDRESS,
//         ["function transfer(address, uint256) returns (bool)", "function decimals() view returns (uint8)", "function balanceOf(address) view returns (uint256)"],
//         signer
//       );

//       const decimals = await usdtContract.decimals();
//       const currentBalance = await usdtContract.balanceOf(address);
//       const transferAmount = ethers.parseUnits(user.amount.toString(), decimals);

//       if (currentBalance < transferAmount) {
//         throw new Error("Insufficient USDT balance");
//       }

//       const tx = await usdtContract.transfer(user.bep20, transferAmount);
//       await tx.wait();

//       dispatch(updateWithdrawal({ id, status: "complete", amount: user?.amount + user.deduction, user_id: user?.id }));

//       await fetchBalances();
//       setIsModalOpen(false);
//     } catch (err) {
//       console.error("Transfer error:", err);
//       setError(err.message || "Transfer failed. Please try again.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const switchToBNBChain = async () => {
//     try {
//       await window.ethereum.request({
//         method: "wallet_switchEthereumChain",
//         params: [{ chainId: BNB_CHAIN_ID }],
//       });
//     } catch (err) {
//       if (err.code === 4902) {
//         try {
//           await window.ethereum.request({
//             method: "wallet_addEthereumChain",
//             params: [
//               {
//                 chainId: BNB_CHAIN_ID,
//                 chainName: "BNB Smart Chain",
//                 nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
//                 rpcUrls: ["https://bsc-dataseed.binance.org/"],
//                 blockExplorerUrls: ["https://bscscan.com/"],
//               },
//             ],
//           });
//         } catch (addError) {
//           console.error("Failed to add BNB Chain:", addError);
//         }
//       } else {
//         console.error("Switch chain error:", err);
//       }
//     }
//   };

//   const handleModal = async () => {
//     try {
//       if (connectors.length > 0) {
//         await connect({ connector });
//       } else {
//         setError("No available wallet connector found.");
//       }
//     } catch (err) {
//       setError("Failed to connect wallet. Try using WalletConnect.");
//     }
//   };

//   return (
//     <div>
//       {isConnected ? (
//         <button className="w-full bg-yellow-500 text-white px-5 py-2 rounded-xl hover:bg-yellow-600 shadow-xl font-semibold" onClick={() => setIsModalOpen(true)}>
//           Send USDT
//         </button>
//       ) : (
//         <button className="w-full bg-yellow-500 text-white px-5 py-2 rounded-xl hover:bg-yellow-600 shadow-xl font-semibold" onClick={handleModal}>
//           Connect Wallet
//         </button>
//       )}

//       {isModalOpen && (
//         <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
//           <div className="bg-white rounded-lg w-full max-w-xl p-6">
//             <div className="bg-yellow-50 p-4 rounded-lg">
//               <p className="text-sm text-gray-600">BNB Balance</p>
//               <p className="text-2xl font-bold text-yellow-600">{bnbBalance} BNB</p>
//             </div>

//             <div className="bg-green-50 p-4 rounded-lg">
//               <p className="text-sm text-gray-600">USDT Balance</p>
//               <p className="text-2xl font-bold text-green-600">${usdtBalance}</p>
//             </div>

//             <select className="bg-white text-black px-3 py-2 rounded-2xl font-semibold border" onChange={(e) => e.target.value === "disconnect" && disconnect()}>
//               <option value="account">{address?.slice(0, 6)}...{address?.slice(-4)}</option>
//               <option value="disconnect">Disconnect</option>
//             </select>

//             <div className="bg-gray-50 p-4 rounded-lg space-y-3">
//               <p className="text-sm text-gray-500">Recipient Address</p>
//               <p className="text-sm break-all font-medium text-black">{user?.bep20}</p>

//               <p className="text-sm text-gray-500">Amount</p>
//               <p className="text-lg font-semibold text-black">${Number(user?.amount).toFixed(2)} USDT</p>

//               <button onClick={handleTransfer} disabled={isLoading} className="w-full bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-4 rounded transition-colors duration-200 disabled:opacity-50">
//                 {isLoading ? <AiOutlineLoading3Quarters className="animate-spin" /> : "Confirm USDT Transfer"}
//               </button>

//               {error && <div className="mt-4 p-3 bg-red-100 border text-red-700 rounded">{error}</div>}
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default USDTTransfer;
