import React from "react";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";
import { FaUser, FaEnvelope } from "react-icons/fa";
import { FiLink2 } from "react-icons/fi";
import { FiCheck, FiCopy } from "react-icons/fi";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import UserTransaction from "./UserTransaction";
import { getTreeData } from "../redux/referralSlice";
import { getctoListByid } from "../redux/ctoSlice";
import { getUser } from "../redux/userSlice";
import { getAllDepositeByid } from "../redux/depositeSlice";
import { getAllWithdrawalByid } from "../redux/withdrawalSlice";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import UserRewardDetail from "./UserRewardDetail";
import Trading from "./Trading";
import NotificationPopup from "./NotificationPopup";
import NotificationList from "./NotificationList";
import { UserAchievement } from "./UserAchivement";
import { Link } from "react-router-dom";
import UserTradingView from "./UserTradingView";

const UserDashboard = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.auth);
  const { singleuser } = useSelector((state) => state.allusers);
  const { singlecto } = useSelector((state) => state.cto);
  const { singleDeposite } = useSelector((state) => state.alldeposite);
  const { singleWithdrawal } = useSelector((state) => state.allwithdrawal);
  const { treeData } = useSelector((state) => state.referralTree);
  const [topGenerations, setTopGenerations] = useState([]);
  const [totalBusiness, setTotalBusiness] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const thresholds = [2500, 7500, 17500, 37500, 87500, 187500, 387500, 887500];


  useEffect(() => {
    dispatch(getUser(auth?.id));
    dispatch(getAllDepositeByid(auth?.id));
    dispatch(getAllWithdrawalByid(auth?.id));
    dispatch(getTreeData(auth?.refferal_code));
  }, [auth?.id]);
  useEffect(() => {
    if (singleuser?.cto == "true") {
      dispatch(getctoListByid(auth?.id));
    }
  }, [singleuser, auth?.id]);

  const referralCode = singleuser?.refferal_code;
  let registerUrl;
  registerUrl = `https://www.finrain.info/registration?referral=${referralCode}`;

  const handleCopy = () => {
    navigator.clipboard
      .writeText(registerUrl)
      .then(() => {
        alert("Referral link copied to clipboard!");
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy referral link: ", err);
      });
  };

  let combinedArray = [];
  const depositsWithType =
    singleDeposite?.map((deposit) => ({ ...deposit, type: "deposit" })) || [];
  const withdrawalsWithType =
    singleWithdrawal?.map((withdrawal) => ({
      ...withdrawal,
      type: "withdrawal",
    })) || [];
  if (withdrawalsWithType.length > 0) {
    combinedArray = [...depositsWithType, ...withdrawalsWithType];
    combinedArray.sort((a, b) => new Date(a.createdAT) - new Date(b.createdAT));
  }
  const totalDeposits = depositsWithType?.reduce(
    (total, deposit) => total + (deposit.amount || 0),
    0
  );
  const totalWithdrawals = withdrawalsWithType?.reduce(
    (total, withdrawal) =>
      total + (withdrawal.amount || 0) + (withdrawal.deduction || 0),
    0
  );

  function countTotalTeamWithActiveInactive(user) {
    let totalTeam = 0;
    let activeCount = 0;
    let inactiveCount = 0;
    const stack = [user];

    while (stack.length > 0) {
      const currentUser = stack.pop();
      totalTeam += 1;

      if (currentUser.is_active === "active") {
        activeCount += 1;
      } else if (currentUser.is_active === "inactive") {
        inactiveCount += 1;
      }
      if (currentUser.referrals && currentUser.referrals.length > 0) {
        stack.push(...currentUser.referrals);
      }
    }

    return { totalTeam, activeCount, inactiveCount };
  }

  const totalDirectActiveMembers = treeData?.filter(
    (user) => user.is_active === "active"
  ).length;
  const totalDirectInactiveMembers = treeData?.filter(
    (user) => user.is_active === "inactive"
  ).length;

  let totalTeamCount = 0;
  let totalActiveMembers = 0;
  let totalInactiveMembers = 0;

  treeData?.forEach((user) => {
    const { totalTeam, activeCount, inactiveCount } =
      countTotalTeamWithActiveInactive(user);
    totalTeamCount += totalTeam;
    totalActiveMembers += activeCount;
    totalInactiveMembers += inactiveCount;
  });

  /// Function to calculate business for a given user and their entire team (referrals and descendants)
  const calculateBusinessForTeam = (user) => {
    let totalBusiness = user.active_plan || 0;

    // If user has referrals, recursively add business for each referral
    if (user.referrals && user.referrals.length > 0) {
      user.referrals.forEach((referral) => {
        totalBusiness += calculateBusinessForTeam(referral); // Recursively calculate for all referrals
      });
    }

    return totalBusiness;
  };

  useEffect(() => {
    if (treeData) {
      const businessByLeg = calculateBusinessForLegs(treeData);

      // Extract and sort legs by total business
      const sortedLegs = Object.entries(businessByLeg)
        .map(([legId, totalBusiness]) => ({
          legId: parseInt(legId),
          totalBusiness,
        }))
        .sort((a, b) => b.totalBusiness - a.totalBusiness);

      // Determine the top two legs
      const topTwoLegs = sortedLegs.slice(0, 2);

      // Sum up the total business of all legs
      const totalBusiness = Object.values(businessByLeg).reduce(
        (acc, value) => acc + value,
        0
      );

      // Calculate the third leg as the sum of all other legs
      const thirdLegTotalBusiness = sortedLegs
        .slice(2)
        .reduce((acc, leg) => acc + leg.totalBusiness, 0);

      // Combine top two legs and the third leg
      const topGenerations = [
        ...topTwoLegs,
        { legId: "Other", totalBusiness: thirdLegTotalBusiness },
      ];

      setTopGenerations(topGenerations);
      setTotalBusiness(totalBusiness);
    }
  }, [treeData]);

  const calculateBusinessForLegs = (users) => {
    const result = {};

    users?.forEach((user) => {
      result[user.id] = calculateTeamBusiness(user);
    });

    return result;
  };

  const calculateTeamBusiness = (user) => {
    let totalBusiness = user.active_plan || 0;

    if (user.referrals && user.referrals.length > 0) {
      user.referrals.forEach((referral) => {
        totalBusiness += calculateTeamBusiness(referral);
      });
    }

    return totalBusiness;
  };

  const cardData = [
    {
      id: 1,
      value: `$ ${singleuser?.active_plan}`,
      description: "Active Package",
      borderColor: "border-blue-500 ",
      svgPath: (
        <>
          <path d="M8 17l4 4 4-4m-4-5v9"></path>
          <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
        </>
      ),
      bgColor: "bg-blue-500",
      iconBgColor: "bg-blue-700",
      gredient: "border-t bg-gradient-to-r from-gray-900 to-blue-900",
      percentage: 45,
    },
    {
      id: 2,
      value: `$ ${singleuser?.roi_income}`,
      description: "ROI",
      borderColor: "border-green-500 ",
      svgPath: (
        <>
          <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
          <circle cx="9" cy="7" r="4"></circle>
          <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
        </>
      ),
      bgColor: "bg-green-500",
      iconBgColor: "bg-green-700",
      gredient: "border-t bg-gradient-to-r from-green-900 to-gray-900",
      percentage: 25,
    },
    {
      id: 3,
      value: `$ ${singleuser?.level_income}` || 0,
      description: "Level Income",
      upadtePlan: "Need Support",
      borderColor: "border-red-500 ",
      svgPath: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 12h16.5m-16.5 6h16.5m-16.5-12h16.5"
            />
          </svg>
        </>
      ),
      bgColor: "bg-red-500",
      iconBgColor: "bg-red-700",
      gredient: " border-t bg-gradient-to-r from-gray-900 to-red-900",
      percentage: 55,
    },
    {
      id: 4,
      value: `$ ${singleuser?.reward}`,
      description: "Reward",
      upadtePlan: "Add More",
      borderColor: "border-yellow-500 ",
      percentage: 25,
      svgPath: (
        <>
          <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
        </>
      ),
      bgColor: "bg-yellow-500",
      iconBgColor: "bg-yellow-700",
      gredient: "border-t bg-gradient-to-r from-yellow-900 to-gray-900",
    },
    {
      id: 5,
      value: `$ ${singleuser?.total_salary}`,
      description: "Salary",
      upadtePlan: "Add More",
      borderColor: "border-pink-500 ",
      percentage: 21,
      svgPath: (
        <>
          <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
        </>
      ),
      bgColor: "bg-pink-500",
      iconBgColor: "bg-pink-700",
      gredient: "border-t bg-gradient-to-r from-pink-900 to-gray-900",
    },
    {
      id: 6,
      value: `$ ${singlecto?.amount || 0}`,
      description: "CTO Amount",
      upadtePlan: "Add More",
      borderColor: "border-pink-500 ",
      percentage: 21,
      svgPath: (
        <>
          <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
        </>
      ),
      bgColor: "bg-pink-500",
      iconBgColor: "bg-pink-700",
      gredient: "border-t bg-gradient-to-r from-pink-900 to-gray-900",
    },
    {
      id: 7,
      value:
        `$ ${(
          singleuser?.total_salary +
          singleuser?.level_income +
          singleuser?.roi_income +
          singleuser?.reward +(singlecto?.amount || 0)
          
        ).toFixed(2)}` || " - ",
      description: "Total Earning",
      upadtePlan: "Add More",
      borderColor: "border-pink-500 ",
      percentage: 21,
      svgPath: (
        <>
          <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
        </>
      ),
      bgColor: "bg-pink-500",
      iconBgColor: "bg-pink-700",
      gredient: "border-t bg-gradient-to-r from-pink-900 to-gray-900",
    },
    {
      id: 8,
      value: `$ ${totalWithdrawals}`,
      description: "Total Withdrawal",
      upadtePlan: "Copy Link",
      percentage: 20,
      borderColor: "border-indigo-500 ",
      svgPath: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12H9m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </>
      ),
      bgColor: "bg-indigo-500",
      iconBgColor: "bg-indigo-700",
      gredient: "border-t bg-gradient-to-r from-indigo-900 to-gray-900",
    },

    {
      id: 9,
      // value: `$ $` || 0,
      value: `$${totalBusiness}`,
      description: "Total Business",
      percentage: 75,
      borderColor: "border-purple-500 ",
      // upadtePlan: "Copy Link",
      svgPath: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12H9m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </>
      ),
      bgColor: "bg-purple-500",
      iconBgColor: "bg-purple-700",
      gredient: "border-t bg-gradient-to-r from-purple-900 to-gray-900",
    },
    {
      id: 10,
      value: `${
        topGenerations?.[0]?.totalBusiness
          ? "$" + topGenerations?.[0]?.totalBusiness
          : 0
      }`,
      description: "Team A",
      percentage: 10,
      // upadtePlan: "Copy Link",
      svgPath: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12H9m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </>
      ),
      borderColor: "border-orange-500 ",
      bgColor: "bg-orange-500",
      iconBgColor: "bg-orange-700",
      gredient: "border-t bg-gradient-to-r from-orange-900 to-gray-900",
    },
    {
      id: 11,
      value: `${
        topGenerations?.[1]?.totalBusiness
          ? "$" + topGenerations?.[1]?.totalBusiness
          : 0
      }`,
      description: "Team B",
      percentage: 13,
      // upadtePlan: "Copy Link",
      svgPath: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12H9m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </>
      ),
      bgColor: "bg-sky-500",
      iconBgColor: "bg-sky-700",
      borderColor: "border-sky-500 ",
      gredient: "border-t bg-gradient-to-r from-sky-900 to-gray-900",
    },
    {
      id: 12,
      value: `${
        topGenerations?.[2]?.totalBusiness
          ? "$" + topGenerations?.[2]?.totalBusiness
          : 0
      }`,
      description: "Team Others",
      percentage: 48,
      // upadtePlan: "Copy Link",
      svgPath: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12H9m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </>
      ),
      bgColor: "bg-amber-500",
      borderColor: "border-amber-500 ",
      iconBgColor: "bg-amber-700",
      gredient: "border-t bg-gradient-to-r from-amber-900 to-gray-900",
    },
    {
      id: 13,
      value: `$${((thresholds[singleuser?.reward_level]*0.40) - (topGenerations?.[0]?.totalBusiness || 0)) > 0 ? ((thresholds[singleuser?.reward_level]*0.40) - (topGenerations?.[0]?.totalBusiness || 0)) : 0}`,
      description: "Required Team A",
      percentage: 10,
      // upadtePlan: "Copy Link",
      svgPath: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12H9m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </>
      ),
      borderColor: "border-orange-500 ",
      bgColor: "bg-orange-500",
      iconBgColor: "bg-orange-700",
      gredient: "border-t bg-gradient-to-r from-orange-900 to-gray-900",
    },
    {
      id: 14,
      value: `$${((thresholds[singleuser?.reward_level]*0.30) - (topGenerations?.[1]?.totalBusiness || 0)) > 0 ? ((thresholds[singleuser?.reward_level]*0.30) - (topGenerations?.[1]?.totalBusiness || 0)) : 0}`,
      description: "Required Team B",
      percentage: 13,
      // upadtePlan: "Copy Link",
      svgPath: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12H9m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </>
      ),
      bgColor: "bg-sky-500",
      iconBgColor: "bg-sky-700",
      borderColor: "border-sky-500 ",
      gredient: "border-t bg-gradient-to-r from-sky-900 to-gray-900",
    },
    {
      id: 15,
      value: `$${((thresholds[singleuser?.reward_level]*0.30) - (topGenerations?.[2]?.totalBusiness || 0)) > 0 ? ((thresholds[singleuser?.reward_level]*0.30) - (topGenerations?.[2]?.totalBusiness || 0)) : 0}`,
      description: "Required Others Team",
      percentage: 48,
      // upadtePlan: "Copy Link",
      svgPath: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12H9m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </>
      ),
      bgColor: "bg-amber-500",
      borderColor: "border-amber-500 ",
      iconBgColor: "bg-amber-700",
      gredient: "border-t bg-gradient-to-r from-amber-900 to-gray-900",
    },
    {
      id: 16,
      value: `${10-(singleuser?.month_duration>0 ?singleuser?.month_duration : 10 )} Months` || 0,

      description: "Remaining Month for Salary",
      percentage: 48,
      // upadtePlan: "Copy Link",
      svgPath: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M15 12H9m0 0l3-3m-3 3l3 3"
            />
          </svg>
        </>
      ),
      bgColor: "bg-amber-500",
      borderColor: "border-amber-500 ",
      iconBgColor: "bg-amber-700",
      gredient: "border-t bg-gradient-to-r from-amber-900 to-gray-900",
    },
   
  ];

  const stat = [
    {
      name: "Total Direct",
      href: "#",
      members: `${
        totalDirectActiveMembers + totalDirectInactiveMembers
      } Member`,
      icon: CursorArrowRaysIcon,
      bgColor: "bg-purple-600",
      percentage: 45,
      iconColor: "text-purple-500",
      gredient: "bg-gradient-to-r from-gray-900 to-purple-900 shadow-lg ",
    },

    // {
    //   name: "Active Sponser",
    //   initials: "AS",
    //   href: "#",
    //   members: `${totalDirectActiveMembers} Member`,
    //   bgColor: "bg-blue-600",
    //   iconColor: "text-blue-500",
    //   percentage: 66,
    //   icon: CursorArrowRaysIcon,
    //   gredient: "bg-gradient-to-r from-gray-900 to-blue-900 shadow-lg ",
    // },
    // {
    //   name: "Inactive Sponser",
    //   initials: "IS",
    //   href: "#",
    //   icon: CursorArrowRaysIcon,
    //   members: `${totalDirectInactiveMembers} Member`,
    //   bgColor: "bg-red-600",
    //   iconColor: "text-red-500",
    //   percentage: 99,
    //   gredient: "bg-gradient-to-r from-gray-900 to-red-900 shadow-lg ",
    // },
    {
      name: "Reward Rank",
      // initials: "RR",
      href: "#",
      icon: CursorArrowRaysIcon,
      members: `${singleuser?.reward_level} Level`,
      bgColor: "bg-green-600",
      iconColor: "text-green-500",
      percentage: 88,
      gredient: "bg-gradient-to-r from-gray-900 to-green-900 shadow-lg ",
    },
  ];

  const incomedetail = [
    {
      id: 1,
      name: "Total Team",
      title: "Total Team ",
      stat: `${totalTeamCount} Member` || 0,
      icon: CursorArrowRaysIcon,
      iconColor: "text-indigo-500",
      change: "3.2%",
      changeType: "decrease",
      bgColor: "bg-indigo-800",
      percentage: 55,
      gredient:
        "bg-gradient-to-r from-indigo-900 to-gray-900 shadow-lg shadow-indigo-500/50",
    },
    // {
    //   id: 2,
    //   name: "Referral Code",
    //   title: "Referral Code",
    //   stat: singleuser?.refferal_code,
    //   icon: ClipboardDocumentIcon,
    //   iconColor: "text-red-500",
    //   change: "1.2%",
    //   changeType: "increase",
    //   percentage: 50,
    //   bgColor: "bg-red-800",
    //   gredient:
    //     "bg-gradient-to-r from-red-900 to-gray-900 shadow-lg shadow-red-500/50",
    // },

    // {
    //   id: 3,
    //   name: "Reffer By",
    //   title: "Referral Code",
    //   stat: singleuser?.reffer_by || " - ",
    //   icon: CursorArrowRaysIcon,
    //   iconColor: "text-yellow-500",
    //   change: "3.2%",
    //   changeType: "decrease",
    //   percentage: 24,
    //   bgColor: "bg-yellow-800",
    //   gredient:
    //     "bg-gradient-to-r from-gray-900 to-yellow-900 shadow-lg shadow-yellow-500/50",
    // },

    {
      id: 4,
      name: "Status",
      stat: singleuser?.status || " - ",
      icon: ClipboardDocumentIcon,
      iconColor: "text-orange-500",
      change: "1.2%",
      changeType: "increase",
      bgColor: "bg-orange-800",
      percentage: 29,
      gredient:
        "bg-gradient-to-r from-orange-900 to-gray-900 shadow-lg shadow-red-500/50",
    },
    {
      id: 5,
      name: "Active Team",
      stat: `${totalActiveMembers} Member`,
      icon: UsersIcon,
      iconColor: "text-green-500",
      change: "122",
      changeType: "increase",
      bgColor: "bg-green-800",
      percentage: 20,
      gredient:
        "bg-gradient-to-r from-green-900 to-gray-900 shadow-lg shadow-green-500/50",
    },
    {
      id: 6,
      name: "Inactive Team",
      stat: `${totalInactiveMembers} Member` || "0",
      icon: EnvelopeOpenIcon,
      iconColor: "text-blue-500",
      change: "5.4%",
      changeType: "increase",
      percentage: 51,
      bgColor: "bg-blue-800",
      gredient:
        "bg-gradient-to-r from-gray-900 to-blue-900 shadow-lg shadow-blue-500/50",
    },
  ];

  return (
    <>
      <div className="">
        <Trading />
      </div>
      <div className="px-4 py-2">
        <div className="text-white my-2 text-lg">Notification List </div>
        <NotificationList />
      </div>
      <div className="flex justify-between w-full gap-5  px-2 ">
        <section className="text-gray-700 body-font  mx-2  mt-4 pb-6 w-full">
          {/* <div className="grid   grid-cols-1">
            <div className=" ">
              <div className=" border  border-[#1e2761] py-4 mb-5 drop-shadow-lg shadow--500/50 p-5 rounded-sm">
                <div className="">
                  <dl className="">
                    <dd className=" flex items-center justify-between  text-lg font-medium capitalize text-gray-700">
                      <h1 className="   text-base text-gray-200 font-bold">
                        Welcome
                      </h1>
                      <div className="flex items-center gap-3 text-gray-300">
                        <CheckCircleIcon
                          aria-hidden="true"
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                        />
                        Dashboard
                      </div>
                    </dd>
                  </dl>
                  <h1 className=" overflow-hidden whitespace-nowrap  pr-5 text-base text-gray-200 font-semibold">
                     {singleuser?.username}
                  </h1>
                  <p className="text-base text-gray-300 mt-1">
                     {singleuser?.email}
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          <div className="relative w-full mb-4  rounded-sm bg-gradient-to-r from-[#7a2048] to-[#1e2761]">
            <div className="relative z-10 p-6">
              <div className="space-y-2">
                <div className="sm:flex items-center gap-4 text-lg">
                  <h2 className="text-lg font-semibold text-white">Welcome {singleuser?.fullname ? singleuser?.fullname : ""}</h2>
                  <div className="flex  items-center gap-1  text-gray-300">
                    <CheckCircleIcon
                      aria-hidden="true"
                      className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                    />
                    Dashboard
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-lg text-white/80">
                    <span className="bg-blue-500 rounded-sm flex justify-center items-center px-[10px] py-2">
                      <FaUser className="text-white" />
                    </span>
                    {singleuser?.username}
                  </div>
                  <p className="flex items-center gap-2 text-base text-gray-300 mt-1">
                    <span className="bg-indigo-500 rounded-sm flex justify-center items-center px-[9px] py-2">
                      <FaEnvelope className="text-gray-300" />
                    </span>
                    <span> {singleuser?.email}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="absolute sm:-top-10 -top-5 sm:right-0 -right-5 h-full ">
              <div className="relative h-full w-full transform perspective-1000">
                <img
                  src="/bot2.png"
                  alt="Football player"
                  className="object-contain w-48  transition-transform duration-300 hover:scale-110 hover:rotate-6"
                  style={{
                    transform: "rotateY(15deg) translateZ(20px)",
                  }}
                  priority
                />
              </div>
            </div>
          </div>

          {/* <div className="lg:col-span-8 col-span-12 "> 
            <UserAchievement />
            </div> */}
          <div className="grid md:grid-cols-3 sm:grid-cols-2  grid-cols-1 gap-2 w-full">
            {/* <button className="flex-1 border-[#D67B1D] text-white py-3 font-semibold border-r-2 border-l-2 bg-gradient-to-r from-[#7a2048] to-[#1e2761] transition-colors">
              <div className="flex items-center justify-center gap-2 text-lg">
                Total Earning
                <span className="text-lg">
                  {" "}
                  ${( 
          singleuser?.level_income +
          singleuser?.roi_income +(singlecto?.amount || 0)).toFixed(2)}
                </span>
              </div>
            </button> */}
            <button className="flex-1 border-[#D67B1D] text-white py-3 font-semibold border-r-2  border-l-2 bg-gradient-to-r from-[#48207a] to-[#3080db] transition-colors">
              <div className="flex items-center justify-center gap-2 text-lg">
                Remaining Limit
                <span className="text-lg">
                  {" "}
                  $
                  {(
                    (singleuser?.active_plan * singleuser?.max) -
                    (singleuser?.level_income +
                    singleuser?.roi_income +(singlecto?.amount || 0))
                  ).toFixed(2)}
                </span>
              </div>
            </button>
            <button className="flex-1 border-[#D67B1D] text-white py-3 font-semibold border-r-2  border-l-2 bg-gradient-to-r from-[#124800] to-[#6b0b0b] transition-colors">
              <div className="flex items-center justify-center gap-2 text-lg">
                Referral LINK
                <button
                  onClick={handleCopy}
                  className={`inline-flex items-center px-2  text-lg font-medium rounded-md transition-colors duration-200 ease-in-out ${
                    isCopied ? "text-green-500  " : "text-blue-700  "
                  }`}
                >
                  {isCopied ? (
                    <>
                      <FiCheck className="h-5 w-5 " aria-hidden="true" />
                    </>
                  ) : (
                    <>
                      <FiCopy className="h-5 w-5 " aria-hidden="true" />
                    </>
                  )}
                </button>
              </div>
            </button>
            <button className="flex-1 border-[#D67B1D] text-white py-3 font-semibold border-r-2  border-l-2 bg-gradient-to-r from-[#7a5020] to-[#076a6e] transition-colors">
              <div className="flex items-center justify-center gap-2 text-lg">
                Tokens
                <span className="text-lg">
                  {" "}
                  
                  {singleuser?.tokens ? singleuser?.tokens : 0}
                </span>
              </div>
            </button>
          </div>
        </section>
      </div>
      <div className="w-full px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {cardData.map((card) => (
            <div
              key={card.id}
              className={` border  ${card.borderColor}  shadow p-4  bg-blue-900/70`}
            >
              <div className="flex items-center justify-between mb-3">
                <div
                  className={`w-10 h-10 ${card.iconBgColor} rounded flex items-center justify-center`}
                >
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="text-white w-6 h-6  "
                    viewBox="0 0 24 24"
                  >
                    {card.svgPath}
                  </svg>
                </div>
                <div className="">
                  <p className="text-base text-gray-300 ">{card.value}</p>
                  {(card.id > 0 && card.id < 9) && (
                  <p className="text-lg text-gray-300 ">
                    {card.description}
                  </p>
                  )}
                </div>
              </div>
              <div className="space-y-2">
                <div className="bg-blue-300 rounded-full h-2">
                  <div
                    className={`bg-${card.gredient} h-2 rounded-full`}
                    style={{ width: `${card.percentage}%` }}
                  />
                </div>
                {(card.id ==1) && (
                <div className="flex items-center text-lg text-gray-300">
                  <div className="flex-1">Roi percentage</div>
                  <div>{singleuser?.roi_percentage}%</div>
                </div>
                )}
                {/* {(card.id >1 && card.id < 9 ) && (
                <div className="flex items-center text-lg text-gray-300">
                  <div className="flex-1"></div>
                  <div>{card.description}</div>
                </div>
                )} */}
                 {card.id > 8 && (
                <div className="flex items-center text-lg text-gray-300">
                  <div className="flex-1"></div>
                  <div>{card.description}</div>
                </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 my-6">
          <div className="bg-blue-900/50 flex justify-center items-center">
            <UserTradingView />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {incomedetail.map((item) => (
              <div
                key={item.id}
                className={`border ${item.borderColor}  shadow p-4 border bg-green-900/70`}
              >
                <div className="flex items-center justify-between mb-3">
                  <div
                    className={`w-10 h-10 ${item.bgColor} text-gray-200 rounded flex items-center justify-center`}
                  >
                    <item.icon aria-hidden="true" className="h-6 w-6  " />
                  </div>
                  <div className="">
                    <p className="text-base text-gray-300 "> {item.name}</p>
                    <p className="text-lg text-gray-300 ">{item.stat}</p>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="bg-blue-300 rounded-full h-2">
                    <div
                      className={`${item.gredient} h-2 rounded-full`}
                      style={{ width: `${item.percentage}%` }}
                    />
                  </div>
                  {/* <div className="flex items-center text-lg text-gray-300">
                    <div className="flex-1">For last week</div>
                    <div>{item.percentage}%</div>
                  </div> */}
                </div>
              </div>
            ))}
            {stat.map((project) => (
              <div
                key={project.name}
                className={` border ${project.borderColor} bg-green-900/70 shadow p-4`}
              >
                <div className="flex items-center justify-between mb-3">
                  <div
                    className={`w-10 h-10 ${project.bgColor} text-gray-200 rounded flex items-center justify-center`}
                  >
                    <project.icon aria-hidden="true" className="h-6 w-6  " />
                  </div>
                  <div className="">
                    <a className="text-base text-gray-300 ">{project.name}</a>
                    <p className="text-lg text-gray-300 ">
                      {project.members}
                    </p>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="bg-blue-300 rounded-full h-2">
                    <div
                      className={`${project.gredient}  h-2 rounded-full`}
                      style={{ width: `${project.percentage}%` }}
                    />
                  </div>
                  {/* <div className="flex items-center text-lg text-gray-300">
                  <div className="flex-1">For last week</div>
                  <div>{project.percentage}%</div>
                </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <div className="flex flex-col lg:flex-row justify-between w-full  gap-5 ">
        <main className="flex-1 overflow-y-auto px-5 pt-2 pb-5 rounded-md  ">
          <div>
            <div className="relative group py-2 inline-block">
              <h3 className="text-base font-semibold leading-6 text-gray-300">
                Last 30 days
              </h3>
              <span className="absolute left-0 bottom-0 w-full h-0.5 mb-2 bg-blue-500 opacity-0 transition-all duration-300 group-hover:opacity-100 group-hover:scale-x-100 origin-left transform scale-x-0"></span>
            </div>
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 ">
              {incomedetail.map((item) => (
                <div
                  key={item.id}
                  className={`relative overflow-hidden rounded-lg ${item.gredient} px-4 py-5  shadow sm:p-6 transition duration-300 ease-out transform hover:scale-105 hover:shadow-lg group ${item.iconColor}`}
                >
                  <dt className="flex items-center">
                    <div
                      className={`rounded-lg p-3 bg-black ${item.bgColor} ${item.iconColor} transition duration-500 ease-in-out animate-border-color hover:animate-pulse`}
                    >
                      <item.icon
                        aria-hidden="true"
                        className="h-6 w-6 animate-bounce "
                      />
                    </div>
                    <div className="ml-4">
                      <p className="truncate text-lg font-medium text-gray-400 whitespace-pre-line break-all">
                        {item.name}
                      </p>
                      <p className="text-lg font-semibold text-gray-400 whitespace-pre-line break-all">
                        {item.stat}
                      </p>
                    </div>
                  </dt>
                  <span
                    className={`absolute left-0 bottom-0 w-full h-0.5 ${item.iconColor.replace(
                      "text-",
                      "bg-"
                    )} opacity-0 transition-all duration-300 group-hover:opacity-100 group-hover:scale-x-100 origin-left transform scale-x-0`}
                  ></span>
                </div>
              ))}
            </dl>
          </div>
        </main>
      </div> */}

      <div className="px-1">
        <aside className=" w-full overflow-y-auto px-3 py-2 rounded-md ">
          <h2 className="text-lg font-semibold text-gray-300 ">
            Transaction History
          </h2>
          <div className="">
            <div className="mt-1 flow-root">
              <div className=" overflow-x-auto">
                <div className="inline-block min-w-full py-2 align-middle">
                  <UserTransaction />
                </div>
              </div>
            </div>
          </div>
        </aside>
      </div>
    </>
  );
};

export default UserDashboard;
