import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import {
  useWeb3Modal,
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  useWeb3ModalState,
  useDisconnect,
} from "@web3modal/ethers/react";

// Configure Web3Modal
createWeb3Modal({
  ethersConfig: defaultConfig({
    metadata: {
      name: "USDT Transfer App",
      description: "Transfer USDT across different networks",
    },
  }),
  chains: [
    {
      chainId: 56,
      name: "BNB Smart Chain",
      currency: "BNB",
      explorerUrl: "https://bscscan.com",
      rpcUrl: "https://bsc-dataseed.binance.org",
    },
  ],
  projectId: "b00311bb20f1d71b977b474eac2b7dcd", // Get this from cloud.walletconnect.com
});

const USDT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955"; // BSC USDT
const USDT_ABI = [
  "function balanceOf(address) view returns (uint256)",
  "function decimals() view returns (uint8)",
  "function transfer(address, uint256) returns (bool)",
];

export default function Connect() {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const { chainId } = useWeb3ModalState();
  const { disconnect } = useDisconnect();
  const { walletProvider } = useWeb3ModalProvider();

  const [bnbBalance, setBnbBalance] = useState("0.00");
  const [usdtBalance, setUsdtBalance] = useState("0.00");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");



  const fetchBalances = async () => {
    if (!address || !walletProvider) return;

    try {
      const ethersProvider = new ethers.BrowserProvider(walletProvider);

      // Get BNB Balance
      const bnbBal = await ethersProvider.getBalance(address);
      setBnbBalance(ethers.formatEther(bnbBal));

      // Get USDT Balance
      const usdtContract = new ethers.Contract(
        USDT_ADDRESS,
        USDT_ABI,
        ethersProvider
      );

      const decimals = await usdtContract.decimals();
      const usdtBal = await usdtContract.balanceOf(address);
      setUsdtBalance(ethers.formatUnits(usdtBal, decimals));
    } catch (err) {
      setError("Failed to fetch balances");
    }
  };
 
  // Function to fetch BNB and USDT balances

  useEffect(() => {
    if (isConnected && address) {
      fetchBalances();
    }
  }, [isConnected, address, chainId]);

  // Switch to BSC if not already on it
  const switchToBNBChain = async () => {
    try {
      await walletProvider?.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }], // BSC Mainnet
      });
    } catch (err) {
      if (err.code === 4902) {
        try {
          await walletProvider?.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x38",
                chainName: "BNB Smart Chain",
                nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
                rpcUrls: ["https://bsc-dataseed.binance.org/"],
                blockExplorerUrls: ["https://bscscan.com/"],
              },
            ],
          });
        } catch (addError) {
          setError("Failed to add BSC network");
        }
      } else {
        setError("Failed to switch network");
      }
    }
  };

  return (
    <div className="w-full max-w-md border rounded-lg shadow-lg bg-white">
      <div className="p-6">
        <h2 className="text-xl font-bold mb-4">BSC Wallet</h2>
        {isConnected ? (
          <div className="space-y-4">
            <div className="bg-yellow-50 p-4 rounded-lg">
              <p className="text-sm text-gray-600">BNB Balance</p>
              <p className="text-2xl font-bold text-yellow-600">
                {bnbBalance} BNB
              </p>
            </div>

            <div className="bg-green-50 p-4 rounded-lg">
              <p className="text-sm text-gray-600">USDT Balance</p>
              <p className="text-2xl font-bold text-green-600">
                ${usdtBalance}
              </p>
            </div>

            <div className="flex flex-col md:flex-row gap-2">
              <select
                className="bg-white px-3 py-2 rounded-2xl font-semibold border"
                onChange={(e) => {
                  if (e.target.value === "disconnect") disconnect();
                }}
              >
                <option value="account">
                  {address?.slice(0, 6)}...{address?.slice(-4)}
                </option>
                <option value="disconnect">Disconnect</option>
              </select>
            </div>
          </div>
        ) : (
          <button
            className="w-full bg-yellow-500 text-white px-5 py-2 rounded-xl hover:bg-yellow-600 shadow-xl font-semibold"
            onClick={() => open()}
          >
            Connect Wallet
          </button>
        )}
      </div>
    </div>
  );
}
