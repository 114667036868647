import React from 'react'
import Header from '../../CoreFile/Header'
import Footer from '../../CoreFile/Footer'
import { TermsHeroSection } from './TermsHeroSection'



export const Terms = () => {
  return (
  <>
  <Header/>
  <TermsHeroSection/>
<div className="bg-white text-black dark:bg-black dark:text-white mt-4">
  <div className="max-w-7xl mx-auto  sm:px-0 px-2 py-8">
    <div className="w-full flex">
      <h1 className="text-3xl font-bold mb-0 text-green-600">Terms and Conditions </h1>
    </div>
    <br />
    <p className="mb-4 text-base text-justify">
    To access FinRain and use our services, you must create an account with accurate and complete information. By using our platform, you agree to adhere to the terms outlined below.


    </p>
    <br />
    {/* repeat this section for each section of terms */}
    <div className="pb-5">
      <h2 className="font-bold text-green-600 dark:text-orange-300"> Trading and Transactions

      </h2>
      <br /> 
      <p className="text-base text-justify">
      When using FinRain for trading, you agree to comply with all applicable laws and regulations.
          </p>
      <ul className='px-2'>
        <li className=" mt-2 ">
          <p className="text-base text-justify">
          🔹FinRain is not liable for trading errors or incorrect transactions.
          </p>
        </li>
        <li className=" mt-2 ">
          <p className="text-base text-justify">
          🔹Always review transaction details carefully before confirming.
          </p>
        </li>
        <li className=" mt-2 ">
          <p className="text-base text-justify">
          🔹Transaction fees may vary based on the exchange platform or trade type.
          </p>
        </li>
        <li className=" mt-2 ">
          <p className="text-base text-justify">
          🔹Users are solely responsible for ensuring the accuracy and authorization of their trades.
          </p>
        </li>
        <br />
        
      </ul>
    </div> 
    <br/>
    <div className="pb-5">
      <h2 className="font-bold text-green-600 dark:text-orange-300">     Account Security

      </h2>
      <br /> 
      {/* <p className="text-base text-justify">
      Account Security
          </p> */}
      <ul className='px-2'>
        <li className=" mt-2 ">
          <p className="text-base text-justify">
          🔹Keep your account credentials, including your password, secure at all times.
          </p>
        </li>
        <li className=" mt-2 ">
          <p className="text-base text-justify">
          🔹Notify us immediately if you suspect unauthorized access to your account.


          </p>
        </li>
        <li className=" mt-2 ">
          <p className="text-base text-justify">
          🔹Notify us immediately if you suspect unauthorized access to your account.

          </p>
        </li>
        <li className=" mt-2 ">
          <p className="text-base text-justify">
          🔹Follow platform security guidelines for a safe experience.
          </p>
        </li>
        <br />
        
      </ul>
    </div>
    <div className="pb-5">
      <h2 className="font-bold text-green-600 dark:text-orange-300">Limitation of Liability
      </h2>
      <br /> 
      <p className="text-base text-justify">
      While FinRain is committed to providing a secure and seamless trading experience, we are not liable for : -
          </p>
      <ul className='px-4'>
        <li className="list-disc mt-2 ">
          <p className="text-base text-justify">
          Financial losses, including lost funds or incorrect trades.

          </p>
        </li>
        <li className="list-disc mt-2 ">
          <p className="text-base text-justify">
          Technical errors, service interruptions, or system outages.
          </p>
        </li>
        <li className="list-disc mt-2 ">
          <p className="text-base text-justify">
          Market fluctuations that may affect trading outcomes.
          </p>
        </li>
        <br />
        
      </ul>
    </div>
    <div className="pb-5">
      
      <ul>
        <li className="">
          <p className="text-base text-justify">
          We encourage all users to stay informed about market conditions and carefully monitor their trading activities.
          </p>
        </li>
        <br />
        
      </ul>
    </div>
  </div>
</div>

  <Footer/>
  </>
  )
}
