import React from "react";
import Header from "../../CoreFile/Header";
import Footer from "../../CoreFile/Footer";
import { PrivacyHeroSection } from "./PrivacyHeroSection";


export const Privacy = () => {
  return (
    <>
      <Header />
      <PrivacyHeroSection/>
      <div className="bg-white py-12 mt-5">
        <div className=" mx-auto max-w-7xl px-2 sm:px-0">
          <div className="">
            {/* Header */}
            <h1 className="text-4xl font-extrabold text-gray-800  mb-6">
              <span className="text-[#006A4E]">Privacy Policy</span>
            </h1>
            {/* Section: Introduction */}
            <section className="text-base text-gray-600 mb-8 leading-relaxed">
              <p className="mb-4 ">
              Welcome to{" "}
                <span className="font-semibold text-[#006A4E]">
                  !At Finrain,
                </span>{" "}
               
              </p>
              <p className="text-base text-justify  px-4 mb-3">
              At FinRain, we value your privacy and are committed to safeguarding your personal information. This Privacy Policy explains how we collect, use, and protect your data when you interact with our website and services.
              </p>
            </section>
            {/* Divider */}
            <div className="border-b border-gray-300 my-6" />
            {/* Section: Information We Collect */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                <span className="bg-[#006A4E] text-white w-8 h-8 flex justify-center items-center rounded-full mr-3">
                  1
                </span>
                Information We Collect
              </h2>
              <p className="text-base text-justify  px-4 mb-3">
              We may collect the following types of information:-
              </p>
              <ul className="list-inside list-disc text-gray-600 text-base pl-4 space-y-2">
                <li>
                  <strong>Personal Information :</strong> Name, email address, phone number, and payment details.

                </li>
                <li>
                  <strong>Technical Data :</strong>IP address, browser type, and device information.
                </li>
                <li>
                  <strong>Usage Data :</strong>  Interactions with our website, pages visited, and features used.
                </li>
              </ul>
            </section>
            {/* Section: How We Use Information */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                <span className="bg-[#006A4E] text-white w-8 h-8 flex justify-center items-center rounded-full mr-3">
                  2
                </span>
                How We Use Your Information
              </h2>
              <p className="text-base text-justify  px-4 mb-3">
              We use your data to :

          </p>
              <ul className="list-inside list-disc text-gray-600 text-base pl-4 space-y-2">
                <li>
                Provide and improve our services.
                </li>
                <li>
                Process transactions securely.
                </li>
                <li>
                Communicate with you regarding updates or support.
                </li>
                <li>
                Ensure the security and integrity of our platform.
                </li>
              </ul>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                <span className="bg-[#006A4E] text-white w-8 h-8 flex justify-center items-center rounded-full mr-3">
                  3
                </span>
                Sharing of Information
              </h2>
              <p className="text-base text-justify  px-4 mb-3">
              We do not sell or share your personal information with third parties, except :

          </p>
              <ul className="list-inside list-disc text-gray-600 text-base pl-4 space-y-2">
                <li>
                When required by law or to protect our rights

                </li>
                <li>
                To trusted service providers for operational purposes, under strict confidentiality agreements.
                </li>
              </ul>
            </section>
            {/* Section: Data Sharing */}
           
            {/* Section: Account Deletion */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                <span className="bg-[#006A4E] text-white w-8 h-8 flex justify-center items-center rounded-full mr-3">
                  4
                </span>
                Your Rights
              </h2>
              <p className="text-gray-600 mb-4 text-base">
              You have the right to:
              </p>
              <ol className=" list-inside text-base pl-4 text-gray-600 space-y-2">
                <li>
                1️⃣ Access and update your personal information.

                </li>
                <li>
                2️⃣ Request data deletion or restriction of processing.
                </li>
                <li>
                3️⃣ Withdraw consent for marketing communications.
                </li>
              </ol>
            </section>
            {/* Section: Contact Us */}
            <section className="mb-8">
              <h2 className="text-2xl font-bold text-gray-800 mb-4 flex items-center">
                <span className="bg-[#006A4E] text-white w-8 h-8 flex justify-center items-center rounded-full mr-3">
                  5
                </span>
                Updates to This Policy
              </h2>
              <p className="text-gray-600 mb-4 text-base">
              We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date.
              </p>
             
            </section>
            {/* Section: Data Deletion Form */}
       
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
